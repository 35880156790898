<ng-container *ngIf="changeRx$ | ngrxPush as changeRx">
  <form [formGroup]="form.controls"
        [omgForm]="form.model">
    <omg-change-item-subheader [changeRx]="changeRx"></omg-change-item-subheader>
    <div *ngIf="pediatricVitalsEnabled$ | ngrxPush">
      <omg-pediatric-vitals (vitalsStatus)="updatePediatricVitalsStatus($event)"></omg-pediatric-vitals>
    </div>
    <omg-section-title>Original Prescription</omg-section-title>
    <omg-change-rx-medication-item [medication]="changeRx.originalMedicationPrescription">
    </omg-change-rx-medication-item>
    <ng-container [ngSwitch]="changeRx.changeRequestType">
      <ng-container *ngSwitchCase="'P'">
        <omg-section-title>Prior Authorization Requested</omg-section-title>
        <omg-change-rx-prior-auth [form]="form"></omg-change-rx-prior-auth>
      </ng-container>
      <ng-container *ngSwitchCase="'U'">
        <omg-section-title>Provider Authorization Requested</omg-section-title>
        <omg-change-rx-provider-auth [form]="form"></omg-change-rx-provider-auth>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <omg-section-title>{{ changeRxTypes[changeRx.changeRequestType] }} Requested</omg-section-title>
        <omg-change-rx-medication-change [form]="form"
                                         [changeRx]="changeRx">
        </omg-change-rx-medication-change>
      </ng-container>
    </ng-container>
    <omg-change-item-action-bar [form]="form">
    </omg-change-item-action-bar>
  </form>
</ng-container>

import { map } from 'rxjs/operators';

import { TrackEventProperties } from '@app/core/analytics/shared/analytics.type';
import {
  lettersPath,
  messagingPath,
  notesPath,
  procedureInteractionPath,
  summariesPath,
} from '@app/features/workspace/shared/workspace-utils';
import {
  Message,
  PostPostedBy,
} from '@app/modules/messaging/shared/messaging.type';
import { getOrderEditPath } from '@app/modules/orders/shared/order-routing-utils';
import { Order, OrderTypes } from '@app/modules/orders/shared/order.type';

import {
  TimelineAuthorDetails,
  TimelineItem,
  TimelineItemComment,
} from './timeline.type';

export const checkIfLoadingOrContent = map(
  ([loading, error, timelineItems]: [boolean, any, TimelineItem[]]) =>
    (loading || timelineItems.length) && !error,
);

export const checkIfErrorOrNoContent = map(
  ([loading, error, timelineItems]: [boolean, any, TimelineItem[]]) =>
    (error || !timelineItems.length) && !loading,
);

export const checkIfLoadedNoContent = map(
  ([loading, error, timelineItems]: [boolean, any, TimelineItem[]]) =>
    !loading && !error && !timelineItems.length,
);

export const isDraftLetter = (item: TimelineItem): boolean => {
  return item.noteType === 'Official Letter' && !item.signed;
};

export const mapTimelineItemType = (item: TimelineItem): string => {
  const id = item.summaryId || item.databaseId;
  if (isDraftLetter(item)) {
    return lettersPath(item.patientId, id);
  }
  return item.summaryId
    ? summariesPath(item.patientId, id, 'edit')
    : notesPath(item.patientId, id, 'edit');
};

export const mapTimelineItemToRoute = (item: TimelineItem): string => {
  if (item.doctype === 'patient_timeline_post') {
    return messagingPath(item.patientId, item.databaseId, 'edit');
  }

  if (item.labOrderId) {
    const partialOrder = <Order>{
      type: OrderTypes.LabOrder,
      id: item.labOrderId,
    };
    return getOrderEditPath(item.patientId, partialOrder);
  }

  if (item.type === 'lab_order') {
    const partialOrder = <Order>{
      type: OrderTypes.LabOrder,
      id: item.id,
    };
    return getOrderEditPath(item.patientId, partialOrder);
  }

  if (item.type === 'procedure_interaction') {
    return procedureInteractionPath(item.patientId, item.databaseId);
  }

  return mapTimelineItemType(item);
};

export const getInitials = (name: string) =>
  name
    .split(' ')
    .slice(0, 2)
    .map(i => i.charAt(0))
    .join('')
    .toUpperCase();

export const mapTimelineItemContentToMessage = (
  item: TimelineItem,
): Partial<Message> => {
  const itemContent = (item && item.content) || <any>{};
  return {
    postedBy: <PostPostedBy>{
      id: itemContent.id || null,
      name: itemContent.author || '',
      initials: getInitials(itemContent.author || ''),
    },
    text: itemContent.text || '',
    updatedAt: itemContent.sentAt || itemContent.updatedAt || '',
    s3Pointers: (item && item.s3Pointers) || [],
  };
};

export const mapTimelineItemCommentsToMessages = (
  comments: TimelineItemComment[],
): Partial<Message>[] =>
  (comments || []).reduce((acc, curr) => {
    const commentMessage = {
      postedBy: {
        id: curr.id || null,
        name: curr.author || '',
        initials: getInitials(curr.author || ''),
      },
      text: curr.text || '',
      updatedAt: curr.sentAt || curr.updatedAt || '',
      s3Pointers: curr.s3Pointers || [],
    };
    return [...acc, commentMessage];
  }, []);

export const mapTimelineItemToMessages = (
  item: TimelineItem,
): Partial<Message>[] => [
  mapTimelineItemContentToMessage(item),
  ...mapTimelineItemCommentsToMessages(item && item.comments),
];

const internalUser = 'Internal User';

const hasSignedByDetails = (timelineItem: TimelineItem): boolean => {
  return (
    timelineItem.signedByDetails?.firstName &&
    timelineItem.signedBy !== internalUser
  );
};

const hasAuthorDetails = (timelineItem: TimelineItem): boolean => {
  return (
    timelineItem.authorDetails?.firstName &&
    timelineItem.author !== internalUser
  );
};

const firstInitialLastFromFullName = (name: string): string => {
  if (!name || name === internalUser) {
    return name;
  }
  const split = name.split(' ');
  if (!split[1]) {
    return name;
  }
  split[0] = `${split[0].charAt(0)}.`;
  const firstInitialLastAll = split.join(' ');
  return firstInitialLastAll.split(',')[0];
};

const firstInitialLastFromDetails = (authorDetails: TimelineAuthorDetails) => {
  return `${authorDetails.firstName.slice(0, 1)}. ${authorDetails.lastName}`;
};

const timelineListPostAuthor = (timelineItem: TimelineItem): string => {
  if (timelineItem.draft === true) {
    return null;
  }
  if (timelineItem.authorIsPatient === true) {
    return 'Patient';
  }
  if (hasAuthorDetails(timelineItem)) {
    return firstInitialLastFromDetails(timelineItem.authorDetails);
  }
  return firstInitialLastFromFullName(timelineItem.author);
};

export const mapTimelineItemToAuthorName = (
  timelineItem: TimelineItem,
): string => {
  if (timelineItem.doctype === 'patient_timeline_post') {
    return timelineListPostAuthor(timelineItem);
  }
  if (timelineItem.signed === true) {
    return hasSignedByDetails(timelineItem)
      ? firstInitialLastFromDetails(timelineItem.signedByDetails)
      : firstInitialLastFromFullName(timelineItem.signedBy);
  }
  if (timelineItem.signed === false) {
    return hasAuthorDetails(timelineItem)
      ? firstInitialLastFromDetails(timelineItem.authorDetails)
      : firstInitialLastFromFullName(timelineItem.author);
  }
  return firstInitialLastFromFullName(timelineItem.author);
};

export const mapNoteTypeToAnalyticsSubcomponent = (
  noteType: string,
): string => {
  switch (noteType) {
    // Cases requiring translation
    case 'Laboratory Order':
      return 'Lab Order';
    // Cases where the note type is the correct value, including null
    default:
      return noteType;
  }
};

export const mapTimelineItemToAnalyticsSubcomponent = (
  item: TimelineItem,
): string => {
  if (item.type === 'patient_timeline_post') {
    return 'Message';
  }

  if (
    item.type === 'note' &&
    item.tags &&
    item.tags.includes('misc_clinical')
  ) {
    return 'Misc Clinical';
  }

  return mapNoteTypeToAnalyticsSubcomponent(item.noteType);
};

export const mapTimelineItemToAnalyticsData = (
  item: TimelineItem,
  method: 'Push' | 'Native',
): Partial<TrackEventProperties> => ({
  workflow: 'Charting',
  component: 'Clinical Timeline',
  subcomponent: mapTimelineItemToAnalyticsSubcomponent(item),
  activeCharting: true,
  noteId: item.type === 'note' ? item.id : null,
  orderId: item.labOrderId || null,
  summaryId: item.summaryId || null,
  patientTimelinePostId: item.type === 'patient_timeline_post' ? item.id : null,
  method,
});

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core/store/shared/fsa-action';

import { Comment } from '../shared/comments.type';
import { ChangeRxCommentsState } from './change-rx-comments.reducer';

export enum ChangeRxCommentsActionTypes {
  GetChangeRxComments = '[Comments] Get ChangeRx Comments',
  GetChangeRxCommentsSuccess = '[Comments] Get ChangeRx Comments Success',
  GetChangeRxCommentsError = '[Comments] Get ChangeRx Comments Error',
  CreateChangeRxComment = '[Comments] Create ChangeRx Comments',
  CreateChangeRxCommentSuccess = '[Comments] Create ChangeRx Comments Success',
  CreateChangeRxCommentError = '[Comments] Create ChangeRx Comments Error',
}

type ChangeRxCommentsErrorData = any;

interface CreateChangeRxCommentData {
  commentableId: number;
  commentBody: string;
}

export class GetChangeRxComments implements FsaAction<number> {
  readonly type = ChangeRxCommentsActionTypes.GetChangeRxComments;
  error = false;

  constructor(public payload: number) {}
}

export class GetChangeRxCommentsSuccess implements FsaAction<Comment[]> {
  readonly type = ChangeRxCommentsActionTypes.GetChangeRxCommentsSuccess;
  error = false;

  constructor(public payload: Comment[]) {}
}

export class GetChangeRxCommentsError
  implements FsaAction<ChangeRxCommentsErrorData> {
  readonly type = ChangeRxCommentsActionTypes.GetChangeRxCommentsError;
  error = true;

  constructor(public payload: ChangeRxCommentsErrorData) {}
}

export class CreateChangeRxComment
  implements FsaAction<CreateChangeRxCommentData> {
  readonly type = ChangeRxCommentsActionTypes.CreateChangeRxComment;
  error = false;

  constructor(public payload: CreateChangeRxCommentData) {}
}

export class CreateChangeRxCommentSuccess implements FsaAction<Comment> {
  readonly type = ChangeRxCommentsActionTypes.CreateChangeRxCommentSuccess;
  error = false;

  constructor(public payload: Comment) {}
}

export class CreateChangeRxCommentError
  implements FsaAction<ChangeRxCommentsErrorData> {
  readonly type = ChangeRxCommentsActionTypes.CreateChangeRxCommentError;
  error = true;

  constructor(public payload: ChangeRxCommentsErrorData) {}
}

export type ChangeRxCommentsAction =
  | GetChangeRxComments
  | GetChangeRxCommentsSuccess
  | GetChangeRxCommentsError
  | CreateChangeRxComment
  | CreateChangeRxCommentSuccess
  | CreateChangeRxCommentError;

@Injectable()
export class ChangeRxCommentsActions {
  constructor(private store: Store<ChangeRxCommentsState>) {}

  getChangeRxComments(id: number) {
    this.store.dispatch(new GetChangeRxComments(id));
  }

  createChangeRxComment(commentableId: number, commentBody: string = '') {
    this.store.dispatch(
      new CreateChangeRxComment({ commentableId, commentBody }),
    );
  }
}

export interface StrategyOptions {
  index: string[];
  size: string;
  scroll: string;
  fields: string[];
  filter: string | any;
  sort: string[] | any[];
  operator: string;
}

// Elasticsearch V6-ready options can go below this line:

export const buildQueryStringWithFieldsV6Strategy = (
  query: string,
  options: Partial<StrategyOptions> = {},
) => {
  return {
    index: options.index,
    size: options.size,
    scroll: options.scroll,
    body: {
      sort: options.sort,
      query: {
        bool: {
          must: {
            query_string: {
              query,
              fields: options.fields,
              default_operator: options.operator || 'or',
            },
          },
          filter: options.filter,
        },
      },
    },
  };
};

export const buildMultiMatchWithFieldsV6Strategy = (
  query: string,
  options: Partial<StrategyOptions> = {},
) => {
  return {
    index: options.index,
    size: options.size,
    scroll: options.scroll,
    body: {
      sort: options.sort,
      query: {
        bool: {
          must: {
            multi_match: {
              query,
              fields: options.fields,
              operator: options.operator || 'or',
            },
          },
          filter: options.filter,
        },
      },
    },
  };
};

export const buildFunctionScoreV6Strategy = (
  query: string,
  options: Partial<StrategyOptions> = {},
) => {
  return {
    index: options.index,
    size: options.size,
    scroll: options.scroll,
    body: {
      sort: options.sort,
      query: {
        function_score: {
          query: {
            constant_score: {
              filter: {
                multi_match: {
                  query,
                  fields: options.fields,
                  operator: options.operator || 'or',
                },
              },
            },
          },
          functions:
            options.fields &&
            options.fields.map(field => {
              const [name, boost] = Array.from(field.split('^'));
              return {
                filter: {
                  match: {
                    [name]: query,
                  },
                },
                weight: boost || '1',
              };
            }),
          score_mode: 'first',
        },
      },
    },
  };
};

export const buildFunctionScoreV6StrategyWithFilters = (
  query: string,
  options: Partial<StrategyOptions> = {},
) => {
  return {
    index: options.index,
    size: options.size,
    scroll: options.scroll,
    body: {
      sort: options.sort,
      query: {
        function_score: {
          query: {
            bool: {
              must: [
                {
                  constant_score: {
                    filter: {
                      multi_match: {
                        query,
                        fields: options.fields,
                        operator: options.operator || 'or',
                      },
                    },
                  },
                },
                options.filter,
              ],
            },
          },
          functions:
            options.fields &&
            options.fields.map(field => {
              const [name, boost] = Array.from(field.split('^'));
              return {
                filter: {
                  match: {
                    [name]: query,
                  },
                },
                weight: boost || '1',
              };
            }),
          score_mode: 'first',
        },
      },
    },
  };
};

import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { Observable } from 'rxjs';

import { FocusService } from '@app/shared/directives/focus/focus.service';

export interface ControlOption<T> {
  name: string;
  items$?: Observable<T[]>;
  addCustomItemLabel?: string;
  autoCompleteConfig: {
    placeholder: string;
    trackByKey: string;
    bindLabel: string;
    bindValue: string;
  };
  handlers: {
    blur?: () => void;
    onChange?: (item: T) => void;
    search?: (term: string) => void;
    searchFn?: (term: string, item: any) => boolean;
  };
}
export type FormUpdate = (updatePayload: any) => void;
export type AddCustomItem = () => void;
export interface SearchableHandlers {
  formUpdate?: FormUpdate;
  addCustomItem?: AddCustomItem;
}

export interface SearchableFormGroup {
  controlOptions: ControlOption<any>[];
  searchableHandlers: SearchableHandlers;
}

export interface AddControlOptions {
  name: string;
  validators?: ValidatorFn[] | AbstractControlOptions;
  focusOn?: boolean;
  defaultValue?: any;
}

export class DynamicFormGroup {
  controls: FormGroup;
  focus = this.focusService
    ? this.focusService.setFocus.bind(this.focusService)
    : () => {};

  get value() {
    return this.controls.value;
  }

  get valid() {
    return this.controls.valid;
  }

  constructor(protected focusService?: FocusService) {
    const fb = new FormBuilder();
    this.controls = fb.group({});
  }

  protected addControl({
    name,
    validators = [],
    focusOn = false,
    defaultValue = null,
  }: AddControlOptions) {
    this.controls.addControl(name, new FormControl(defaultValue, validators));
    if (focusOn) {
      this.focus(name);
    }
  }

  protected removeControl(name: string) {
    this.controls.removeControl(name);
  }

  protected toggleControl(
    name: string,
    add: Boolean,
    validators?: ValidatorFn[],
    defaultValue?: any,
  ) {
    return add
      ? this.addControl({ name, validators, focusOn: true, defaultValue })
      : this.removeControl(name);
  }
}

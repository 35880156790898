import * as fromActions from './active-routes.actions';

export interface ActiveRouteItem {
  path: string;
}

export interface ActiveRoutesState {
  orders: ActiveRouteItem;
  workspace: ActiveRouteItem;
}

export const initialState: ActiveRoutesState = {
  orders: null,
  workspace: null,
};

export function reducer(
  state = initialState,
  action: fromActions.ActiveRoutesAction,
): ActiveRoutesState {
  switch (action.type) {
    case fromActions.ActiveRoutesActionTypes.SET_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      };
    }

    case fromActions.ActiveRoutesActionTypes.CLEAR_ORDERS: {
      return {
        ...state,
        orders: null,
      };
    }

    case fromActions.ActiveRoutesActionTypes.SET_WORKSPACE: {
      return {
        ...state,
        workspace: action.payload,
      };
    }

    case fromActions.ActiveRoutesActionTypes.CLEAR_WORKSPACE: {
      return {
        ...state,
        workspace: null,
      };
    }

    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { ToastMessageService } from '@app/shared/components/toast';
import { waitFor } from '@app/utils';

import { RenewalActions, RenewalSelectors } from '../store';
import { RenewalCartState } from './renewals.type';

@Injectable()
export class RenewalGuard implements CanActivate {
  constructor(
    private renewalSelectors: RenewalSelectors,
    private toastService: ToastMessageService,
    private router: Router,
    private renewalActions: RenewalActions,
    private patientSelectors: PatientSelectors,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const renewalId = +route.paramMap.get('id');

    return waitFor(
      this.patientSelectors.patientId,
      patientId => !!patientId,
    ).pipe(
      switchMap(() =>
        this.renewalSelectors.getById(renewalId).pipe(
          withLatestFrom(this.renewalSelectors.cartComplete),
          take(1),
          switchMap(([renewal, cartComplete]) => {
            if (!renewal || cartComplete) {
              this.renewalActions.loadRenewalCart();
              // Action dispatching is async - this ensures that the `loadRenewalCart` action has been dispatched.
              return waitFor(
                this.renewalSelectors.loading,
                isLoading => isLoading,
              );
            }
            return of(true);
          }),
          switchMap(() => this.renewalSelectors.loading),
          filter(loading => !loading),
          take(1),
          switchMap(() => this.renewalSelectors.getById(renewalId)),
          take(1),
          map(renewal => {
            if (!renewal) {
              this.returnToList('The renewal ID was not found');
              return false;
            }
            if (renewal.cartState !== RenewalCartState.pending) {
              this.returnToList(
                'This renewal cannot be modified while approved or denied',
              );
              return false;
            }
            return true;
          }),
        ),
      ),
    );
  }

  private returnToList(errorMessage: string) {
    this.toastService.add({
      severity: 'error',
      detail: errorMessage,
    });
    this.patientSelectors.patientId
      .pipe(take(1))
      .subscribe(patientId =>
        this.router.navigateByUrl(`/patients/${patientId}/chart/renewals/list`),
      );
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Patient } from '@app/core';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { Note } from '@app/modules/note/shared/note.type';

@Component({
  selector: 'omg-patient-demographics',
  templateUrl: './patient-demographics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDemographicsComponent {
  @Input() patient: Patient;
  @Input() summary: Summary;
  @Input() note: Note;

  get dateOfService(): string {
    // for notes with an appointment/encounter, use the appointment date as the date of service
    if (this.summary && this.summary.appointment) {
      return this.summary.appointment.startAt;
    }
    return this.note.createdAt;
  }
}

import { Injectable } from '@angular/core';

import { ApiService } from '@app/core/api/api.service';

import { FeatureFlag } from './feature-flag.type';

@Injectable()
export class FeatureFlagApiService {
  private featuresPath = '/v2/features';

  constructor(private api: ApiService) {}

  get() {
    return this.api.get<FeatureFlag[]>(this.featuresPath);
  }
}

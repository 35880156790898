import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import { Order } from '@app/modules/orders/shared/order.type';

import { CommentsState } from './comments.reducer';
import { OrderCommentsActions } from './order-comments.actions';
import { PostCommentsActions } from './post-comments.actions';
import { ProcedureInteractionCommentsActions } from './procedure-interaction-comments.actions';
import { RenewalCommentsActions } from './renewal-comments.actions';
import { SummaryCommentsActions } from './summary-comments.actions';
import { ChangeRxCommentsActions } from './change-rx-comments.actions';

export enum CommentsActionTypes {
  DELETE_COMMENT = '[Comments] Delete Comments',
  DELETE_COMMENT_SUCCESS = '[Comments] Delete Comments Success',
  DELETE_COMMENT_ERROR = '[Comments] Delete Comments Error',
}

export type CommentsAction =
  | DeleteComment
  | DeleteCommentSuccess
  | DeleteCommentError;

export type CommentsErrorPayload = any;
export class DeleteComment implements FsaAction<number> {
  readonly type = CommentsActionTypes.DELETE_COMMENT;
  error = false;

  constructor(public payload: number) {}
}

export class DeleteCommentSuccess implements FsaAction<number> {
  readonly type = CommentsActionTypes.DELETE_COMMENT_SUCCESS;
  error = false;

  constructor(public payload: number) {}
}

export class DeleteCommentError implements FsaAction<CommentsErrorPayload> {
  readonly type = CommentsActionTypes.DELETE_COMMENT_ERROR;
  error = true;

  constructor(public payload: CommentsErrorPayload) {}
}
/* istanbul ignore next */
@Injectable()
export class CommentsActions {
  constructor(
    private store: Store<CommentsState>,
    private summaryCommentsActions: SummaryCommentsActions,
    private postCommentsActions: PostCommentsActions,
    private orderCommentsActions: OrderCommentsActions,
    private renewalCommentsActions: RenewalCommentsActions,
    private procedureInteractionCommentsActions: ProcedureInteractionCommentsActions,
    private changeRxCommentsActions: ChangeRxCommentsActions,
  ) {}

  deleteComment(commentId: number) {
    this.store.dispatch(new DeleteComment(commentId));
  }

  getSummaryComments(id: number) {
    this.summaryCommentsActions.getSummaryComments(id);
  }

  createSummaryComment(id: number, body: string) {
    this.summaryCommentsActions.createSummaryComment(id, body);
  }

  getPostComments(id: number) {
    this.postCommentsActions.getPostComments(id);
  }

  createPostComment(id: number, body: string) {
    this.postCommentsActions.createPostComment(id, body);
  }

  getOrderComments(order: Partial<Order>) {
    this.orderCommentsActions.getComments(order);
  }

  createOrderComment(order: Partial<Order>, body: string) {
    this.orderCommentsActions.createComment(order.id, order.type, body);
  }

  getRenewalComments(id: number) {
    this.renewalCommentsActions.getRenewalComments(id);
  }

  createRenewalComment(id: number, body: string) {
    this.renewalCommentsActions.createRenewalComment(id, body);
  }

  getProcedureInteractionComments(id: number) {
    this.procedureInteractionCommentsActions.getProcedureInteractionComments(
      id,
    );
  }

  createProcedureInteractionComment(id: number, body: string) {
    this.procedureInteractionCommentsActions.createProcedureInteractionComment(
      id,
      body,
    );
  }

  getChangeRxComments(id: number) {
    this.changeRxCommentsActions.getChangeRxComments(id);
  }

  createChangeRxComment(id: number, body: string) {
    this.changeRxCommentsActions.createChangeRxComment(id, body);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { NgxLinkifyjsService, NgxLinkifyOptions } from 'ngx-linkifyjs';

export const linkifyOptions = (maxLength: number): NgxLinkifyOptions => ({
  target: {
    url: '_blank',
  },
  className: '-plain-text',
  format: (rawUrl, type) => {
    if (type === 'url' && rawUrl.length > maxLength) {
      return rawUrl.slice(0, maxLength) + '…';
    }
    return rawUrl;
  },
});

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  constructor(private linkifyService: NgxLinkifyjsService) {}

  private truncate(text: string, maxLength: number): string {
    return this.linkifyService.linkify(text, linkifyOptions(maxLength));
  }

  transform(text: string, { maxLength } = { maxLength: 35 }): any {
    return this.truncate(text, maxLength);
  }
}

<form [formGroup]="form.controls">
  <div class="padding-normal"
       om-layout-gutter
       om-layout="vertical">
    <div>Requested by {{changeRx.originalMedicationPrescription.pharmacy.name}}</div>
    <strong>Choose {{ changeRxTypes[changeRx.changeRequestType] }}</strong>
    <omg-change-rx-medication-select [form]="form"
                                     [options]="rxChangeRequestOptions"
                                     (updated)="updatedSelectedRxChangeRequestOption($event)">
    </omg-change-rx-medication-select>
    <omg-collapse om-collapse
                  #regimenEditCollapseRef="omgCollapse"
                  *ngIf="regimenEditable; else staticInstructions">
      <a om-layout-gutter
         omgCollapseToggle="expand"
         class="om-link -plain-text">{{ changeRx.rxChangeRequest.medForDisplay.instructions }}
        <i class="fa fa-pencil"></i>
      </a>
      <omg-expanded>
        <omg-regimen-editor *ngIf="regimenEditCollapseRef.expanded"
                            [medication]="changeRx.rxChangeRequest"
                            (regimenUpdate)="regimenUpdate($event)"
                            (collapse)="regimenEditCollapseRef.collapse()"></omg-regimen-editor>
      </omg-expanded>
    </omg-collapse>
    <ng-template #staticInstructions>
      <span>{{ changeRx.rxChangeRequest.medForDisplay.instructions }}</span>
    </ng-template>
    <div om-layout-gutter
         omgCollapse
         om-layout
         omgCollapse
         #moreOptionsCollapseRef="omgCollapse"
         [expanded]="true"
         om-layout-gutter-large
         om-layout-align="start center">
      <omg-renewal-item-qty [quantity]="form.controls.get('quantity')"
                            [medicationPackageSizeId]="form.controls.get('medicationPackageSizeId')"
                            [packageOptions]="changeRx.rxChangeRequest.packageOptions">
      </omg-renewal-item-qty>
      <omg-renewal-item-fills [fills]="form.controls.get('refills')"></omg-renewal-item-fills>
      <omg-cart-item-more-options-toggle [collapseProvider]="moreOptionsCollapseRef"
                                         [visible]="true">
      </omg-cart-item-more-options-toggle>
    </div>
    <!-- <div class="om-messages"
               *ngFor="let error of form.updateErrors">
            {{ error }}
          </div> -->
    <omg-cart-item-more-options [collapseProvider]="moreOptionsCollapseRef"
                                [dispenseAsWritten]="form.controls.get('dispenseAsWritten')"
                                [notesToPharmacistRequired]="false"
                                [fillAfterDate]="form.controls.get('earliestFillDate')"
                                [fillAfterDateRequired]="changeRx.rxChangeRequest.dispensableRestrictedControlledSubstance"
                                [fillAfterDateVisible]="changeRx.rxChangeRequest.dispensableControlledSubstance"
                                [maxEarliestFillDate]="changeRx.rxChangeRequest.maxEarliestFillDate">
    </omg-cart-item-more-options>
    <hr class="-spaced" />

    <omg-rx-item-sign-and-dea *ngIf="(userCanPrescribe$ | ngrxPush)"
                              [prescriber]="changeRx.rxChangeRequest.prescriber"
                              [validPrescribers]='validPrescribers'
                              [loading$]='isCredentialsLoading$'
                              [credentialItems$]='credentialItems$'
                              [prescriberId]="form.controls.get('prescriberId')"
                              [prescribingCredentialId]="form.controls.get('prescribingCredentialId')">
    </omg-rx-item-sign-and-dea>
    <omg-rx-credential-warning [hasControlledSubstances]="changeRx.rxChangeRequest.dispensableControlledSubstance"
                               [numberOfValidPrescribers]="numberOfValidPrescribers">
    </omg-rx-credential-warning>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'omg-change-rx',
  templateUrl: './change-rx.component.html',
  styleUrls: ['./change-rx.component.scss'],
})
export class ChangeRxComponent implements OnInit {
  empty$: Observable<Boolean>;

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.empty$ = this.route.paramMap.pipe(
      map((params: ParamMap) => +params.get('id')),
      map(changeRxId => !changeRxId),
    );
  }
}

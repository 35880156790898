import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { patientRoute, PatientSelectors } from '@app/core';
import { ApiService } from '@app/core/api/api.service';
import { PatientEntityApiService } from '@app/core/store/shared/entity-api-service';
import { RenewalCountResponse } from '@app/modules/rx-cart/shared/rx-cart-api.type';
import {
  mapRenewalsToRenewalEntities,
  mapRxRequestItem,
} from '@app/modules/shared-rx/utils';

import {
  Renewal,
  RenewalCartResponse,
  RenewalCheckoutRequestData,
} from './renewals.type';

@Injectable({
  providedIn: 'root',
})
export class RenewalsApiService extends PatientEntityApiService<Renewal> {
  options = { params: { ignore401: true } };

  constructor(api: ApiService, patientSelectors: PatientSelectors) {
    super(
      api,
      {
        basePath: '/v2/admin/refill_requests',
        params: null,
      },
      patientSelectors,
    );
  }

  getRenewals(patientId: number): Observable<RenewalCartResponse> {
    return this.api
      .get<RenewalCartResponse>(
        patientRoute(patientId, '/rx_carts/current_renewal'),
        {},
        null,
        true,
      )
      .pipe(map(mapRenewalsToRenewalEntities));
  }

  getRenewalCount(patientId: number): Observable<number> {
    return this.api
      .get<RenewalCountResponse>(
        patientRoute(patientId, '/rx_carts/current_renewal_count'),
      )
      .pipe(map(i => i.count));
  }

  update({ id, changes }): Observable<Renewal> {
    return <Observable<Renewal>>(
      super.update({ id, changes }).pipe(map(mapRxRequestItem))
    );
  }

  createReadytoSignEvent(renewal: Renewal) {
    return this.api
      .save(`/v2/admin/refill_requests/${renewal.id}/ready_to_sign`, {
        class_name: renewal.className,
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  checkout(patientId: number, data: RenewalCheckoutRequestData) {
    return this.api
      .save(
        `/v2/admin/patients/${patientId}/rx_checkout/refill`,
        data,
        this.options,
        true,
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
}

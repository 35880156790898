import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import {
  SummaryHealthGoal,
  SummaryHealthMaintenanceNote,
} from '../../shared/summaries.type';

@Component({
  selector: 'omg-linked-health-maintenance',
  templateUrl: './linked-health-maintenance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedHealthMaintenanceComponent {
  @Input() signed: boolean;
  @Input() healthMaintenanceNote: SummaryHealthMaintenanceNote;
  @Input() healthGoals: SummaryHealthGoal[];
  @Output() unlink = new EventEmitter();

  trackByFn = (index, healthGoal: SummaryHealthGoal) => healthGoal.id || index;

  deleteFromNote() {
    this.unlink.emit();
  }
}

import { findAPharmacyOption } from '@app/modules/pharmacy-picker/shared/patient-pharmacy.type';
import {
  Credential,
  Prescriber,
  PrescriberCredentials,
} from '@app/modules/shared-rx/prescriber-credential.type';
import { head } from '@app/utils';

import { PendingNewRx, RxCart, SignedOnBehalfOfDetails } from './rx-cart.type';

export const requireTwoFactorCheckout = (cartItems: PendingNewRx[]): boolean =>
  cartItems.findIndex(item => item.require2Fa) > -1;

export const allHandwrittenRequestsConfirmed = (
  cartItems: PendingNewRx[],
): boolean =>
  !cartItems.some(item => item.mustHandwrite && !item.handwrittenConfirmed);

export const allSignedRequestsConfirmed = (
  cartItems: PendingNewRx[],
): boolean => !cartItems.some(item => item.require2Fa && !item.readyToSign);

export const findValidPrescriber = (
  prescribers: Prescriber[],
  signedOnBehalfOfId: number,
): Prescriber => {
  prescribers = prescribers || [];
  return prescribers.length === 1
    ? head(prescribers)
    : head(
        prescribers.filter(prescriber => prescriber.id === signedOnBehalfOfId),
      );
};

export const findPrescribingCredential = (
  credentials: PrescriberCredentials,
  credentialId: number,
): Credential =>
  head(
    ((credentials && credentials.prescribingCredentials) || []).filter(
      credential => credential.id === credentialId,
    ),
  );

export const getHandWrittenPrescriptions = (
  cartItems: PendingNewRx[],
): PendingNewRx[] => cartItems.filter(cartItem => cartItem.mustHandwrite);

export const getTransmittedPrescriptions = (
  cartItems: PendingNewRx[],
): PendingNewRx[] => cartItems.filter(cartItem => !cartItem.mustHandwrite);

export const buildCheckoutMessages = () => ({
  defaultValidationError:
    'Sorry, an unknown validation error occured. Please try again.',
  unknownError: 'Sorry, an unknown error occurred.',
  mustHandwrite:
    "Currently, this prescription can't be sent electronically. Please handwrite the prescription now and confirm prior to checkout.",
  mustConfirmHandwritten:
    'All handwritten prescriptions must be confirmed prior to checkout.',
  mustConfirmReadyToSign:
    'All controlled substance prescriptions must be marked ready to sign prior to checkout.',
  handwrittenConfirmation:
    "I've written this prescription and it will be noted in the Rx history.",
  twoFactorAgreement: `By completing the two-factor authentication protocol at this time,
                         you are legally signing the prescription(s) and authorizing the transmission of the above
                         information to the pharmacy for dispensing. The two-factor authentication protocol
                         may only be completed by the practitioner whose name and DEA registration number appear above.`,
  approvePush: 'Please confirm your signature on your mobile device.',
  pushFailed:
    'Sorry, there was an error with the signature confirmation. Please try again.',
  pushSucceeded: 'Signature confirmed, transmitting prescriptions...',
  signatureConfirmed: 'Signature confirmed, transmitting prescriptions...',
  invalidCode: 'Code authentication failed',
  deviceListEmpty:
    'Please set up a two-factor authentication device to complete checkout. If you need assistance, please contact helpdesk@onemedical.com.',
});

export const isComplete = (cart: RxCart) => cart && !!cart.cartCompleteAt;

const cartSize = (cart: RxCart) => {
  if (isComplete(cart) || !cart.cartItems) {
    return 0;
  }

  return cart.cartItems.length;
};

export const isEmpty = (cart: RxCart) => cart && cartSize(cart) === 0;

export const isCheckoutDisabled = (
  cart: RxCart,
  hasControlledSubstances: boolean,
  isPrint: boolean,
  isFind: boolean,
) => {
  let isDisabled = false;

  if (cart.pharmacyId === findAPharmacyOption.id && !isEmpty(cart)) {
    isDisabled = true;
  }

  if (isEmpty(cart)) {
    isDisabled = true;
  }

  if (isPrint && hasControlledSubstances) {
    isDisabled = true;
  }

  if (isFind) {
    isDisabled = true;
  }

  return isDisabled;
};

export const buildSignedOnBehalfOfDetails = (
  cart: RxCart,
  credentials: PrescriberCredentials,
  prescribingCredentialId: number,
): SignedOnBehalfOfDetails => {
  if (!cart) {
    return null;
  }

  const prescriber = (cart.validPrescribers || []).find(
    i => i.id === cart.signedOnBehalfOfId,
  );

  const credential = findPrescribingCredential(
    credentials,
    prescribingCredentialId,
  );

  const signedOnBehalfOfDetails: SignedOnBehalfOfDetails = {
    prescriberId: prescriber && prescriber.id,
    name: prescriber && prescriber.name,
    address: prescriber && prescriber.address,
    prescriberCredentialsId: credential && credential.id,
    deaLicenseNumber: credential && credential.deaLicenseNumber,
    serviceArea: credential && credential.serviceArea,
    stateOfIssue: credential && credential.stateOfIssue,
    supervisingPhysicianId: credential && credential.supervisingPhysicianId,
    supervisingPhysician: credential && credential.supervisingPhysician,
  };

  return signedOnBehalfOfDetails;
};

export const mapDecimalValidationErrors = (controlChecks: any[]): string[] =>
  controlChecks
    .filter(({ control }) => control.hasError('pattern'))
    .map(
      ({ label, control }) =>
        `${label} of ${control.value} is invalid.` +
        ` Fractional ${label.toLowerCase()} not supported.`,
    );

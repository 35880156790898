import { ElementRef } from '@angular/core';
import * as keyCode from 'keycode';

import { HotkeyHandler, HotkeyHandlerType } from './shortcut-click.type';

export const createHotkeyHandler = (
  type: HotkeyHandlerType,
  el: ElementRef,
): HotkeyHandler => {
  if (type === 'focusClick') {
    return () => {
      el.nativeElement.click();
      return false;
    };
  }
};

export const parseHotkeyStringToEventProperties = (hotkey: string) => {
  const ctrlIsMeta = /Mac/i.test(window.navigator.platform);
  const MODIFIERS = {
    ctrlKey: /ctrl\+/i,
    altKey: /alt\+/i,
    metaKey: /meta\+/i,
    shiftKey: /shift\+/i,
  };

  const eventProperties = {} as any;

  Object.entries(MODIFIERS).forEach(([modifier, modifierPattern]) => {
    const newPattern = hotkey.replace(modifierPattern, '');
    if (newPattern !== hotkey) {
      eventProperties[modifier] = true;
    }
    hotkey = newPattern;
  });

  if (ctrlIsMeta && eventProperties.ctrlKey && !eventProperties.metaKey) {
    eventProperties.metaKey = true;
    delete eventProperties.ctrlKey;
  }

  if (hotkey !== '') {
    eventProperties.key = keyCode(hotkey);
  }

  return eventProperties;
};

import { Validators } from '@angular/forms';

import { DynamicFormGroup } from '@app/utils/forms/base';

import { RenewalActions } from '../store';
import { CheckoutRenewal, Renewal, RenewalCartState } from './renewals.type';

export class RenewalCheckoutForm extends DynamicFormGroup {
  constructor(
    private actions: RenewalActions,
    private renewals: Renewal[],
    private acceptsDigitalCommunications: boolean,
    private cartId: number,
  ) {
    super();
    this.addControls();
  }

  checkout() {
    const checkoutData = this.prepareDataForCheckout();
    this.actions.checkout(checkoutData);
  }

  get allSubstancesConfirmed(): boolean {
    return this.checkoutRenewals.every(renewal => renewal.readyToSign);
  }

  get allHandwrittenItemsConfirmed(): boolean {
    return this.checkoutRenewals.every(
      renewal =>
        renewal.handwrittenConfirmed ||
        renewal.cartState === RenewalCartState.denied,
    );
  }

  get notAllDenials(): boolean {
    const allDenials = this.renewals.every(
      renewal => renewal.cartState === RenewalCartState.denied,
    );
    return !allDenials;
  }

  get requiresTwoFactorCheckout(): boolean {
    return (
      this.requireTwoFactorCheckout(this.checkoutRenewals) && this.notAllDenials
    );
  }

  private get checkoutRenewals(): CheckoutRenewal[] {
    return this.controls.get('refillRequests').value;
  }

  get password() {
    return this.controls.get('password').value;
  }

  private prepareDataForCheckout() {
    const { notifyPatient, ...checkoutData } = this.value;
    checkoutData.refillRequests.forEach(checkoutRenewal => {
      checkoutRenewal.notifyPatient = notifyPatient;
    });
    return checkoutData;
  }

  private buildCheckoutRenewals(renewals: Renewal[]): CheckoutRenewal[] {
    return renewals.map(renewal => {
      const readyToSign = !renewal.require2Fa;
      const handwrittenConfirmed = !renewal.mustHandwrite;
      return { ...renewal, readyToSign, handwrittenConfirmed };
    });
  }

  private requireTwoFactorCheckout(renewals: Renewal[]) {
    return renewals.some(renewal => renewal.require2Fa);
  }

  private addControls() {
    this.addControl({
      name: 'rxCartId',
      defaultValue: this.cartId,
      validators: [Validators.required],
    });

    this.addControl({
      name: 'password',
      validators: [Validators.required],
    });

    if (this.requireTwoFactorCheckout(this.renewals)) {
      this.addControl({ name: 'cartPushToken' });
    }

    this.addControl({
      name: 'refillRequests',
      defaultValue: this.buildCheckoutRenewals(this.renewals),
    });

    if (this.notAllDenials) {
      this.addControl({
        name: 'notifyPatient',
        defaultValue: {
          value: this.acceptsDigitalCommunications,
          disabled: !this.acceptsDigitalCommunications,
        },
      });
    }
  }
}

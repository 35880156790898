import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core';

@Component({
  selector: 'omg-app-shell',
  templateUrl: 'app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent {
  constructor() {}
}

import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';

import { isNotNullOrUndefined } from '@app/utils';

import { ReferenceDataKeys } from '../shared/reference-data.type';
import {
  referenceDataPath,
  ReferenceDataState,
} from './reference-data.reducer';

export const selectReferenceDataState = createFeatureSelector<
  ReferenceDataState
>(referenceDataPath);

export const selectReferenceData = createSelector(
  selectReferenceDataState,
  (state: ReferenceDataState, referenceDataKey: ReferenceDataKeys) =>
    state[referenceDataKey],
);

export const selectReferenceDataLoading = createSelector(
  selectReferenceDataState,
  state => state.loading,
);

export const selectReferenceDataError = createSelector(
  selectReferenceDataState,
  state => state.error,
);

@Injectable()
export class ReferenceDataSelectors {
  constructor(private store: Store<ReferenceDataState>) {}

  get loading() {
    return this.store.pipe(select(selectReferenceDataLoading));
  }

  get error() {
    return this.store.pipe(select(selectReferenceDataError));
  }

  get<T>(referenceDataKey: ReferenceDataKeys): Observable<T> {
    return this.store.pipe(
      select(selectReferenceData, referenceDataKey),
      isNotNullOrUndefined(),
    );
  }
}

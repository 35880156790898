<div [formGroup]="form.controls">
  <ng-container [ngSwitch]="options.length">
    <ng-container *ngSwitchCase="1">
      <ng-container *ngTemplateOutlet="optionTemplate; context: {option: options[0]}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <omg-auto-complete #selectedRxChangeRequestOption
                         class="rx-option-selector -limited-choice -search-disabled"
                         formControlName="selectedRxChangeRequestOptionId"
                         [items]="options"
                         [clearable]="false"
                         [searchable]="false"
                         trackByKey="id"
                         (change)="onSelection($event)"
                         resetItemsOnClear="false"
                         bindLabel="medForDisplay.name"
                         bindValue="id">
        <ng-template let-optionData="optionData">
          <ng-container *ngTemplateOutlet="optionTemplate; context: {option: optionData}"></ng-container>
        </ng-template>
        <ng-template #labelTemplate
                     let-item="item">
          <ng-container *ngTemplateOutlet="optionTemplate; context: {option: item}"></ng-container>
        </ng-template>
      </omg-auto-complete>
    </ng-container>
  </ng-container>
  <ng-template #optionTemplate
               let-option="option">
    <omg-checkout-list-item-medication-display [medForDisplay]="option.medForDisplay"
                                               [deaSchedule]="option.dispensableDeaCode"
                                               [earliestFillDate]="option.earliestFillDate"
                                               refillText="Fills">
    </omg-checkout-list-item-medication-display>
    <div *ngIf="option.note">Note: {{option.note}}</div>
    <div *ngIf="option.reason">Reason: {{option.reason}}</div>
    <div *ngIf="option.preferred"><i class="om-icon icon-preferred"></i> Pharmacy Preferred</div>
    <div *ngIf="option.custom"><i class="om-icon icon-file-prescription-light"></i> Customized</div>
  </ng-template>
</div>

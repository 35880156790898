import { mapPatientGetResponseToEntity } from '@app/core/patient/shared/patient-api-mappers';

import {
  FacilityResponse,
  LabDataAutoReportableCheckResponse,
  LabDataPatientResponse,
  LabDataPatientTimelinePostResponse,
  LabDataResponse,
  ObservationRequestResponse,
  ObservationRequestResultResponse,
  OriginatingOfficeResponse,
} from './lab-data-api.type';
import {
  AutoReportableCheck,
  Facility,
  LabData,
  LabDataPatient,
  LabDataPatientTimelinePost,
  ObservationRequest,
  ObservationRequestResult,
  OriginatingOffice,
} from './lab-data.type';

/* istanbul ignore next */
export const mapOriginatingOfficeResponseToEntity = (
  data: OriginatingOfficeResponse,
): OriginatingOffice => ({
  address1: data.address1,
  address2: data.address2,
  addressCsz: data.address_csz,
});

/* istanbul ignore next */
export const mapObservationRequestResultResponseToEntity = (
  data: ObservationRequestResultResponse,
): ObservationRequestResult => ({
  value: data.value,
  abnormal: data.abnormal,
  critical: data.critical,
  cancelled: data.cancelled,
  nameAndCode: data.name_and_code,
  name: data.name,
  unitsIdentifier: data.units_identifier,
  referencesRange: data.references_range,
  notes: data.notes,
});

/* istanbul ignore next */
export const mapObservationRequestResponseToEntity = (
  data: ObservationRequestResponse,
): ObservationRequest => ({
  setId: data.set_id,
  reportHeading: data.report_heading,
  results: data.results.map(mapObservationRequestResultResponseToEntity),
});

/* istanbul ignore next */
export const mapFacilitiesResponseToEntity = (
  data: FacilityResponse,
): Facility => ({
  code: data.code,
  name: data.name,
  streetAddress: data.street_address,
  csz: data.csz,
  director: data.director,
});

/* istanbul ignore next */
export const mapLabDataPatientResponseToEntity = (
  data: LabDataPatientResponse,
): LabDataPatient => ({
  ...mapPatientGetResponseToEntity(data),
  phoneNumber: data.phone_number,
  ssn: data.ssn,
  sex: data.sex,
  streetAddress: data.street_address,
  city: data.city,
});

/* istanbul ignore next */
export const mapPatientTimelinePostResponseToEntity = (
  data: LabDataPatientTimelinePostResponse,
): LabDataPatientTimelinePost => ({
  id: data.id,
  sentAt: data.sent_at,
});

/* istanbul ignore next */
export const mapAutoReportableCheckResponseToEntity = (
  data: LabDataAutoReportableCheckResponse,
): AutoReportableCheck => ({
  description: data.description,
  evaluation: data.evaluation === 'true',
});

/* istanbul ignore next */
export const mapLabDataResponseToEntity = (data: LabDataResponse): LabData => ({
  specimenId: data.specimen_id,
  controlId: data.control_id,
  clientId: data.client_id,
  status: data.status,
  labSourceDisplay: data.lab_source_display,
  autoReported: data.auto_reported,
  autoReportedReason: data.auto_reported_reason,
  autoReportableChecks: data.auto_reportable_checks
    ? data.auto_reportable_checks.map(mapAutoReportableCheckResponseToEntity)
    : null,
  observedAt: data.observed_at,
  specimenReceivedAt: data.specimen_received_at,
  reportedAt: data.reported_at,
  collectionVolume: data.collection_volume,
  fasting: data.fasting,
  additionalInformation: data.additional_information,
  orderingPhysicianName: data.ordering_physician_name,
  npi: data.npi,
  ordererId: data.orderer_id,
  testsOrdered: data.tests_ordered,
  generalNotes: data.general_notes,
  patient: mapLabDataPatientResponseToEntity(data.patient),
  originatingOffice: mapOriginatingOfficeResponseToEntity(
    data.originating_office,
  ),
  observationRequests: data.observation_requests.map(
    mapObservationRequestResponseToEntity,
  ),
  facilities: data.facilities.map(mapFacilitiesResponseToEntity),
  contact: data.contact,
  conversationId: data.conversation_id || null,
  patientTimelinePost: data.patient_timeline_post
    ? mapPatientTimelinePostResponseToEntity(data.patient_timeline_post)
    : null,
  omReferenceRanges: data.om_reference_ranges,
});

import { NgModule } from '@angular/core';

import { ElasticsearchLocationService } from './elasticsearch-location.service';
import { SearchService } from './search.service';

@NgModule({
  imports: [],
  providers: [ElasticsearchLocationService, SearchService],
})
export class SearchModule {
  constructor() {}
}

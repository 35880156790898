import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { Patient, PatientApiService, PatientSelectors } from '@app/core';
import { TimelineActions } from '@app/features/timeline/store/timeline.actions';
import { summariesPath } from '@app/features/workspace/shared/workspace-utils';
import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';
import { ToastMessageService } from '@app/shared/components/toast';
import { waitFor } from '@app/utils';

import { MessagingService } from '../../shared/messaging.service';
import { Post } from '../../shared/messaging.type';

interface DialogData {
  post: Post;
}

@Component({
  selector: 'omg-move-message',
  templateUrl: './move-message.component.html',
  styleUrls: ['./move-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveMessageComponent implements OnInit {
  patientId: number;
  newPatient: Patient;
  newPatientUserInput: string;
  newPatientError: string;

  constructor(
    public dialogRef: DialogRef<MoveMessageComponent>,
    @Inject(OMG_DIALOG_DATA) public data: DialogData,
    private patientSelectors: PatientSelectors,
    private patientApiService: PatientApiService,
    private messagingService: MessagingService,
    private toastService: ToastMessageService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private timelineActions: TimelineActions,
  ) {}

  ngOnInit() {
    waitFor(this.patientSelectors.patientId, () => true).subscribe(
      patientId => (this.patientId = patientId),
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  onMoveToAnotherChart() {
    if (this.newPatient) {
      this.data.post.newPatientId = this.newPatient.id;

      this.messagingService
        .movePost(this.data.post)
        .pipe(take(1))
        .subscribe(
          response => {
            this.toastService.add({
              severity: 'success',
              detail: response.message,
            });
            this.timelineActions.refreshTimeline();
            this.router.navigateByUrl(
              summariesPath(this.patientId, null, 'new'),
            ),
              this.dialogRef.close();
          },
          err => {
            const message = err.data.message;
            this.newPatientError = `Message transfer failed: ${message}`;
          },
        );
    } else {
      this.newPatientError =
        'We were unable to move the message. Please try again.';
    }
  }

  findNewPatient() {
    this.newPatient = null;
    this.newPatientError = null;
    const lookupId = this.extractLookupId();

    if (+lookupId === this.patientId) {
      this.newPatientError =
        "Cannot move a message from a patient's timeline back to itself.";
      /**
       * Have to call to detect changes since
       * dialog container uses OnPush strategy
       */
      this.cd.detectChanges();
    } else {
      this.patientApiService
        .get(lookupId)
        .pipe(take(1))
        .subscribe(
          patient => {
            this.newPatientError = null;
            this.newPatient = patient;

            /**
             * Have to call to detect changes since
             * dialog container uses OnPush strategy
             */
            this.cd.detectChanges();
          },
          err => {
            this.newPatientError =
              'We were unable to find a patient with that ID';

            /**
             * Have to call to detect changes since
             * dialog container uses OnPush strategy
             */
            this.cd.detectChanges();
          },
        );
    }
  }

  extractLookupId(): number {
    const chartUrlRegex = /patients\/(\d*)\/chart/;
    let lookupId = null;

    if (this.newPatientUserInput.match(/^\d*$/)) {
      lookupId = this.newPatientUserInput;
    } else {
      const urlMatch = this.newPatientUserInput.match(chartUrlRegex);
      if (urlMatch) {
        lookupId = urlMatch[1];
      }
    }

    return lookupId;
  }
}

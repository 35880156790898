import { DropdownItem } from '@app/shared';
import { camelCase, httpStatus } from '@app/utils';

import {
  RxCartStatus,
  RxVerification,
  RxVerificationDevice,
  RxVerificationDeviceTypes,
  RxVerificationErrors,
  RxVerifyResponse,
} from './rx-verification.type';

const enum errorMessages {
  invalidCode = 'Security code authentication failed',
  pushFailed = 'Push request could not be sent',
}

export const enum rxVerificationButtons {
  pending = 'Pending Verification...',
  sending = 'Sending Prescriptions...',
  retry = 'Retry Verification',
  print = 'Print',
  send = 'Send',
}

export const handleVerificationErrors = (
  error: RxVerifyResponse,
  status: number,
): RxVerificationErrors => {
  const { passwordVerified, errors } = camelCase(error);
  const exceptions = (errors && errors.exceptions) || [];
  const errorStatus: RxVerificationErrors = {
    invalidPassword: !passwordVerified,
    exceptions,
    invalidCode: false,
    pushFailed: false,
    hasUnknownError: false,
  };

  if (status === httpStatus.UNPROCESSABLE_ENTITY) {
    errorStatus.invalidCode = exceptions.includes(errorMessages.invalidCode);
    errorStatus.pushFailed = exceptions.includes(errorMessages.pushFailed);
  } else if (status === httpStatus.INTERNAL_SERVER_ERROR) {
    errorStatus.hasUnknownError = true;
  }

  return errorStatus;
};

export const mapToButtonText = (
  state: RxVerification,
  cartStatus: RxCartStatus,
): string => {
  const { sending, polling, complete } = state.status;
  const { invalidCode, invalidPassword, pushFailed } = state.errors;

  let buttonText = rxVerificationButtons.send;
  if (sending || polling) {
    buttonText = rxVerificationButtons.pending;
  } else if (complete) {
    buttonText = rxVerificationButtons.sending;
  } else if (invalidCode || invalidPassword || pushFailed) {
    buttonText = rxVerificationButtons.retry;
  } else if (cartStatus.isPrint) {
    buttonText = rxVerificationButtons.print;
  }
  return buttonText;
};

export const prioritizePushDevices = (
  devices: RxVerificationDevice[],
): RxVerificationDevice[] =>
  [...devices].sort(a => -(a.type === RxVerificationDeviceTypes.push) || 1);

export const mapDevicesToDropdownItems = (
  devices: RxVerificationDevice[],
): DropdownItem[] =>
  devices.map(device => ({
    value: device,
    label: [device.type, device.name]
      .join(' - ')
      .replace(/\b\w/g, l => l.toUpperCase()),
  }));

export const readyToVerify = (cartStatus: RxCartStatus) =>
  cartStatus.allReadyToSignItemsConfirmed &&
  cartStatus.allHandwrittenItemsConfirmed;

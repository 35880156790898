import { Injectable } from '@angular/core';
import { FsaAction } from '@app/core';
import { Store } from '@ngrx/store';

import { MessagingState } from './messaging.reducer';

export enum MessagingActionTypes {
  SET_CURRENT_POST = '[Messaging] Set current post',
  CLEAR_POST = '[Messaging] Clear post',
}

type PostId = number;

export class SetCurrentPost implements FsaAction<PostId> {
  readonly type = MessagingActionTypes.SET_CURRENT_POST;

  constructor(public payload: PostId) {}
}

export class ClearPost implements FsaAction<void> {
  readonly type = MessagingActionTypes.CLEAR_POST;
}

export type MessagingAction = SetCurrentPost | ClearPost;

@Injectable({
  providedIn: 'root',
})
export class MessagingActions {
  constructor(private store: Store<MessagingState>) {}

  setCurrentPost(postId: number) {
    this.store.dispatch(new SetCurrentPost(postId));
  }

  clearPost() {
    this.store.dispatch(new ClearPost());
  }
}

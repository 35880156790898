import { Prn } from '@app/modules/medications/shared/prn.type';
import { upsert } from '@app/utils';

export const fullPrnOptions = (
  prns: Prn[],
  prnId?: number,
  prnDescription?: string,
): Prn[] => {
  if (prnId) {
    const newPrn: Prn = {
      id: prnId,
      desc: prnDescription,
      isActive: false,
    };
    return upsert((prn: Prn) => prn.id === prnId, prns, newPrn);
  } else {
    return prns;
  }
};

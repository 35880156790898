import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';

import { FsaAction } from '@app/core';

import {
  MedicationAllergy,
  MedicationDrugInteraction,
  MedicationRoute,
} from '../shared';
import { MedicationState } from './medication.reducer';

type MedicationId = number;
type MedicationError = any;

export enum MedicationActionTypes {
  LoadMedication = '[Medication] Load Medication',
  LoadMedicationSuccess = '[Medication] Load Medication Success',
  LoadMedicationError = '[Medication] Load Medication Error',
  ClearMedications = '[Medication] Clear All Medications',
  LoadDrugAllergyInteractions = '[Medication] Load Drug Allergy Interactions',
  LoadDrugAllergyInteractionsSuccess = '[Medication] Load Drug Allergy Interactions Success',
  LoadDrugAllergyInteractionsError = '[Medication] Load Drug Allergy Interactions Error',
  LoadDrugDrugInteractions = '[Medication] Load Drug Drug Interactions',
  LoadDrugDrugInteractionsSuccess = '[Medication] Load Drug Drug Interactions Success',
  LoadDrugDrugInteractionsError = '[Medication] Load Drug Drug Interactions Error',
}

export class LoadMedication implements FsaAction<MedicationId> {
  readonly type = MedicationActionTypes.LoadMedication;

  constructor(public payload: MedicationId) {}
}

export class LoadMedicationSuccess implements FsaAction<MedicationRoute> {
  readonly type = MedicationActionTypes.LoadMedicationSuccess;
  error = false;

  constructor(public payload: MedicationRoute) {}
}

export class LoadMedicationError implements FsaAction<MedicationError> {
  readonly type = MedicationActionTypes.LoadMedicationError;
  error = true;

  constructor(
    public payload: MedicationError,
    public meta: { id: MedicationId },
  ) {}
}

export class LoadDrugAllergyInteractions implements FsaAction<number> {
  readonly type = MedicationActionTypes.LoadDrugAllergyInteractions;

  constructor(public payload: number) {}
}

export class LoadDrugAllergyInteractionsSuccess
  implements FsaAction<MedicationAllergy[]> {
  readonly type = MedicationActionTypes.LoadDrugAllergyInteractionsSuccess;
  error = false;

  constructor(public payload: MedicationAllergy[]) {}
}

export class LoadDrugAllergyInteractionsError
  implements FsaAction<MedicationError> {
  readonly type = MedicationActionTypes.LoadDrugAllergyInteractionsError;
  error = true;

  constructor(
    public payload: MedicationError,
    public meta: { id: MedicationId },
  ) {}
}

export class LoadDrugDrugInteractions implements FsaAction<number> {
  readonly type = MedicationActionTypes.LoadDrugDrugInteractions;

  constructor(public payload: number) {}
}

export class LoadDrugDrugInteractionsSuccess
  implements FsaAction<MedicationDrugInteraction[]> {
  readonly type = MedicationActionTypes.LoadDrugDrugInteractionsSuccess;
  error = false;

  constructor(public payload: MedicationDrugInteraction[]) {}
}

export class LoadDrugDrugInteractionsError
  implements FsaAction<MedicationError> {
  readonly type = MedicationActionTypes.LoadDrugDrugInteractionsError;
  error = true;

  constructor(
    public payload: MedicationError,
    public meta: { id: MedicationId },
  ) {}
}

export class ClearMedications implements Action {
  readonly type = MedicationActionTypes.ClearMedications;
}

export type MedicationAction =
  | LoadMedication
  | LoadMedicationSuccess
  | LoadMedicationError
  | ClearMedications
  | LoadDrugAllergyInteractions
  | LoadDrugAllergyInteractionsSuccess
  | LoadDrugAllergyInteractionsError
  | LoadDrugDrugInteractions
  | LoadDrugDrugInteractionsSuccess
  | LoadDrugDrugInteractionsError;

/**
 * Service
 */

@Injectable({
  providedIn: 'root',
})
export class MedicationActions {
  constructor(private store: Store<MedicationState>) {}

  loadMedication(medicationId: number) {
    this.store.dispatch(new LoadMedication(medicationId));
  }

  clearMedications() {
    this.store.dispatch(new ClearMedications());
  }

  loadDrugAllergyInteractions(medicationRouteId: number) {
    this.store.dispatch(new LoadDrugAllergyInteractions(medicationRouteId));
  }

  loadDrugDrugInteractions(medicationRouteId: number) {
    this.store.dispatch(new LoadDrugDrugInteractions(medicationRouteId));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'omgInjectSlash',
})
export class InjectSlashPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    if (
      value.match(/^\d{2}$/) !== null ||
      value.match(/^\d{2}\/\d{2}$/) !== null
    ) {
      return value.concat('/');
    } else if (value.match(/^\d{2}\/\d{3}$/) !== null) {
      return `${value.substr(0, value.length - 1)}/${value.substr(
        value.length - 1,
        value.length,
      )}`;
    }
    return value;
  }
}

import { ErrorHandler, Provider } from '@angular/core';
import { environment } from '@environments/environment';

import { ErrorHandlerService } from './error-handler.service';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';

const globalErrorHandlerProvider: Provider = {
  provide: ErrorHandler,
  useClass: ErrorHandlerService,
};

const rollbarProviders: Provider[] = [
  {
    provide: ErrorHandler,
    useClass: RollbarErrorHandler,
  },
  {
    provide: RollbarService,
    useFactory: rollbarFactory,
  },
];

export const errorProviders: Provider[] = [];
if (environment.production) {
  errorProviders.push(globalErrorHandlerProvider);
}
if (environment.rollbar.enabled) {
  errorProviders.push(rollbarProviders);
}

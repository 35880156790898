import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { mapAwsResponseToEntity } from './aws-session.mapper';
import { AwsSession, AwsSessionResponse } from './aws-session.type';

@Injectable({
  providedIn: 'root',
})
export class AwsSessionService {
  private readonly SESSION_PATH = '/v2/aws_session';

  constructor(private apiService: ApiService) {}

  get(): Observable<AwsSession> {
    return this.apiService
      .save<AwsSessionResponse>(this.SESSION_PATH, {})
      .pipe(map(mapAwsResponseToEntity));
  }
}

import { DOCUMENT } from '@angular/common';
import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[omgCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input() copy: string;
  @Input() context: string;
  @Output() copied: EventEmitter<any> = new EventEmitter<any>();
  listener = (clipEvent: ClipboardEvent) => {
    const clipboard = clipEvent.clipboardData;
    const copyPayload = {
      text: this.copy,
      context: this.context,
    };

    clipboard.setData('text', this.copy.toString());
    clipEvent.preventDefault();
    this.copied.emit(copyPayload);
  };

  constructor(@Inject(DOCUMENT) private document: Document) {}

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.copy) {
      return;
    }
    this.document.addEventListener('copy', this.listener);
    this.document.execCommand('copy');
    this.document.removeEventListener('copy', this.listener);
  }
}

import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Summary } from '@app/features/summaries/shared/summaries.type';

import { ToastMessageService } from '../../shared/components/toast';
import {
  HelpFlowTypeResponse,
  HelpRequestMutationResponse,
  USStatesResponse,
} from './help-request.type';

export interface HelpRequestOptions {
  patientId: number;
  linkedNoteId?: number;
  summary?: Partial<Summary>;
  currentUrl?: string;
}
export interface HelpRequestDetails {
  options: HelpRequestOptions;
  helpflowType: string;
  details: string;
  licensingBodyId: string;
  callerOnTheLine: boolean;
  callbackName?: string;
  callbackNumber?: string;
}

const GetUSStatesQuery = gql`
  query States {
    states {
      id
      name
      shortName
    }
  }
`;

const GetHelpFlowTypesQuery = gql`
  query HelpFlowTypes {
    helpflowTypes {
      id
      displayName
    }
  }
`;

const CreateHelpRequestMutation = gql`
  mutation CreateHelpRequest(
    $helpflowType: String!
    $linkedNoteId: Int
    $patientId: ID!
    $currentUrl: String
    $details: String
    $licensingBodyId: ID!
    $callerOnTheLine: Boolean!
    $callbackName: String!
    $callbackNumber: String!
  ) {
    createHelpRequest(
      input: {
        helpflowType: $helpflowType
        linkedNoteId: $linkedNoteId
        licensingBodyId: $licensingBodyId
        callerOnTheLine: $callerOnTheLine
        callbackName: $callbackName
        callbackNumber: $callbackNumber
        patientId: $patientId
        actionUrl: $currentUrl
        details: $details
      }
    ) {
      success
      helpRequest {
        id
        createdAt
        description
        slackChannel
      }
      errors
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class HelpRequestService {
  constructor(
    private apollo: Apollo,
    private toastService: ToastMessageService,
  ) { }

  getHelpFlowTypes() {
    return this.apollo
      .query<HelpFlowTypeResponse>({
        query: GetHelpFlowTypesQuery,
      })
      .pipe(map(response => response.data));
  }

  getUSStates(): Observable<USStatesResponse> {
    return this.apollo
      .query<USStatesResponse>({
        query: GetUSStatesQuery,
      })
      .pipe(map(response => response.data));
  }

  getHelp(requestDetails: HelpRequestDetails) {
    const {
      details,
      options,
      helpflowType,
      licensingBodyId,
      callerOnTheLine,
      callbackName,
      callbackNumber,
    } = requestDetails;
    const { currentUrl, linkedNoteId, patientId } = options;
    const variables = {
      helpflowType,
      details,
      currentUrl,
      linkedNoteId,
      patientId,
      licensingBodyId,
      callerOnTheLine,
      callbackName,
      callbackNumber,
    };

    return this.apollo
      .mutate({
        mutation: CreateHelpRequestMutation,
        variables,
      })
      .pipe(
        tap((helpRequestResponse: HelpRequestMutationResponse) => {
          this.successToast(
            helpRequestResponse.data.createHelpRequest.helpRequest.slackChannel,
          );
        }),
      );
  }

  private successToast(channelName: string) {
    this.toastService.add({
      severity: 'success',
      summary: 'Your request has been assigned',
      detail: `View your request in the <b>${channelName}</b> channel.`,
    });
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeatureFlagApiService } from './shared/feature-flag-api.service';
import { FeatureFlagActions } from './store/feature-flag.actions';
import { FeatureFlagEffects } from './store/feature-flag.effects';
import {
  featureFlagReducer,
  featureFlagStatePath,
} from './store/feature-flag.reducer';
import { FeatureFlagSelectors } from './store/feature-flag.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(featureFlagStatePath, featureFlagReducer),
    EffectsModule.forFeature([FeatureFlagEffects]),
  ],
  providers: [FeatureFlagApiService, FeatureFlagActions, FeatureFlagSelectors],
})
export class FeatureFlagModule {}

import { NgModule } from '@angular/core';

import { CookieService } from './shared/cookie.service';
import { LoginGuard } from './shared/login-guard';
import { LogoutGuard } from './shared/logout.guard';

@NgModule({
  providers: [CookieService, LoginGuard, LogoutGuard],
})
export class AuthModule {
  constructor() {}
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import {
  first,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { FeatureFlagSelectors, Patient, PatientSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { Note, NoteFormInfo } from '@app/modules/note/shared/note.type';
import { NoteActions, NoteSelectors } from '@app/modules/note/store';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { FormModel, FormModelOptions } from '@app/shared';
import { isTruthy, waitFor } from '@app/utils';

import { NotesGuard } from '../../shared/notes.guard';

@Component({
  selector: 'omg-note-container',
  templateUrl: './note-container.component.html',
  styleUrls: ['./note-container.component.scss'],
})
export class NoteContainerComponent implements OnInit {
  note$: Observable<Note>;
  todo$: Observable<Todo>;
  canCloneLetter$: Observable<boolean>;
  canRedactNote$: Observable<boolean>;
  hasSlashInsertion$: Observable<boolean>;
  hasHistoricalProcedureCaptureFeature$: Observable<boolean>;
  patient$: Observable<Patient>;
  noteFormModel: FormModel;

  constructor(
    private route: ActivatedRoute,
    private noteSelectors: NoteSelectors,
    private todoSelectors: TodoSelectors,
    private patientSelectors: PatientSelectors,
    private features: FeatureFlagSelectors,
    private summariesActions: SummariesActions,
    private todoActions: TodoActions,
    private noteActions: NoteActions,
    private notesGuard: NotesGuard,
  ) {}

  ngOnInit(): void {
    this.notesGuard
      .canActivate(this.route.snapshot)
      .pipe(isTruthy())
      .subscribe(() => {
        this.initObservables();
        this.initForm();
      });
  }

  private initObservables(): void {
    this.note$ = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('id')),
      map(Number.parseInt),
      switchMap(id => this.noteSelectors.noteById(id)),
      isTruthy(),
    );
    this.todo$ = this.note$.pipe(
      switchMap(note => this.todoSelectors.todoById(note.todoId)),
    );
    this.canCloneLetter$ = this.features.featureEnabled(
      FeatureFlagNames.new1lifeGenerateLetter,
    );
    this.canRedactNote$ = this.features.featureEnabled(
      FeatureFlagNames.noteRedact,
    );
    this.hasSlashInsertion$ = this.features.featureEnabled(
      FeatureFlagNames.slashInsertionForMiscNotes,
    );
    this.hasHistoricalProcedureCaptureFeature$ = this.features.featureEnabled(
      FeatureFlagNames.mammogramColonoscopyDataCaptureForm,
    );
    this.patient$ = this.patientSelectors.patient;
  }

  private initForm(): void {
    // Adding attachments and documents as arrays to get autosave functionality
    // NOTE: This is non-standard behaviour and should not be used as a reference
    const group = new FormGroup({
      id: new FormControl(),
      subject: new FormControl(),
      body: new FormControl(),
      attachments: new FormControl(),
      documents: new FormControl(),
    });
    const options: FormModelOptions = {
      autosave: true,
      autosaveDelay: 200,
      saveFunction: this.autosave.bind(this),
    };
    this.noteFormModel = new FormModel(group, options);

    // patch the form with the note
    // note$ is derived from the store/state which is updated every time the user
    // types into the form (due to autosave continuously updating the letter in the
    // backend and in the store/state). we only want the first emitted value
    // because we only want to patch the form once upon initialization
    this.note$.pipe(take(1)).subscribe(note => {
      this.noteFormModel.patchValue(note);
    });
  }

  private autosave(note: Note): Observable<Note> {
    // this autosave handler is invoked by the FormModel whenever form changes occur.
    // the FormModelSaveFunction contract says it must return an observable that completes once autosaving is done.
    // when triggered, dispatch an action to persist the note in the backend.
    // return an observable that emits a value once the success | error action arrives.
    // on update success, the returned observable emits the updated note.
    // on update failure, the returned observable throws the error.
    this.noteActions.updateNote(note);
    return this.noteSelectors.isPending.pipe(
      first(pending => !pending),
      withLatestFrom(this.noteSelectors.error),
      switchMap(([_, error]: [boolean, Error]) => {
        return error ? throwError(error) : of(note);
      }),
    );
  }

  onNoteClose(): void {
    this.summariesActions.closeWorkspaceItem();
  }

  onNoteFinish(todo: Todo): void {
    // This way we make sure no save is currently occuring
    waitFor(this.noteSelectors.isPending, pending => !pending).subscribe(() => {
      if (todo && todo.isManual) {
        this.todoActions.completeWithoutSigningNote(todo);
      } else {
        this.todoActions.completeAndSignNote(todo);
      }
    });
  }

  onNoteReopen(todo: Todo) {
    this.todoActions.reopenTodo(todo);
  }

  onNoteDelete(note: Note) {
    this.noteActions.deleteNote(note.id);
  }

  onNoteUnfile(note: Note) {
    this.noteActions.unfileNote(note.id);
  }

  onNoteClone(note: Note) {
    this.noteActions.cloneNote(note.id);
  }
}

import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import { createEntityMetadataSelectors } from '@app/utils/store';

import * as fromMedication from './medication.reducer';
import { selectMedicationsRootState } from './medications-store-shared';
import { MedicationsRootState } from './medications-store.reducer';

// selects the state slice
export const selectMedicationState = createSelector(
  selectMedicationsRootState,
  state => state.medications,
);

// selects array of ids
export const selectMedicationIds = createSelector(
  selectMedicationState,
  fromMedication.selectMedicationIds,
);

// selects the dictionary
export const selectMedicationEntities = createSelector(
  selectMedicationState,
  fromMedication.selectMedicationEntities,
);

// selects the array
export const selectAllMedications = createSelector(
  selectMedicationState,
  fromMedication.selectAllMedications,
);

// selects by item id
export const selectMedicationById = createSelector(
  selectMedicationState,
  (state, { id }) => state.entities[id],
);

// selects the total number count
export const selectTotalMedications = createSelector(
  selectMedicationState,
  fromMedication.selectMedicationTotal,
);

// selects loading
export const selectLoadingMedications = createSelector(
  selectMedicationState,
  state => state && state.loading,
);

// selects the general state error
export const selectMedicationsError = createSelector(
  selectMedicationState,
  state => state && state.error,
);

export const selectDrugAllergyInteractions = createSelector(
  selectMedicationState,
  state => state && state.drugAllergyInteractions,
);

export const selectLoadingDrugAllergyInteractions = createSelector(
  selectMedicationState,
  state => state && state.drugAllergyInteractionsLoading,
);

export const selectDrugDrugInteractions = createSelector(
  selectMedicationState,
  state => state && state.drugDrugInteractions,
);

export const selectLoadingDrugDrugInteractions = createSelector(
  selectMedicationState,
  state => state && state.drugDrugInteractionsLoading,
);

/**
 * Entity Metadata
 */

const {
  selectEntityMetadata,
  selectEntityWithMetadata,
  selectEntityError,
} = createEntityMetadataSelectors(selectMedicationState, selectMedicationById);

export const selectMedicationMetadata = selectEntityMetadata;
export const selectMedicationWithMetadata = selectEntityWithMetadata;
export const selectMedicationError = selectEntityError;

/**
 * Service
 */

@Injectable({
  providedIn: 'root',
})
export class MedicationSelectors {
  constructor(private store: Store<MedicationsRootState>) {}

  medication(id: number) {
    return this.store.pipe(select(selectMedicationById, { id }));
  }

  medicationMetadata(id: number) {
    return this.store.pipe(select(selectMedicationMetadata, { id }));
  }

  medicationWithMetadata(id: number) {
    return this.store.pipe(select(selectMedicationWithMetadata, { id }));
  }

  drugAllergyInteractions() {
    return this.store.pipe(select(selectDrugAllergyInteractions));
  }

  drugAllergyInteractionsLoading() {
    return this.store.pipe(select(selectLoadingDrugAllergyInteractions));
  }

  drugDrugInteractions() {
    return this.store.pipe(select(selectDrugDrugInteractions));
  }

  drugDrugInteractionsLoading() {
    return this.store.pipe(select(selectLoadingDrugDrugInteractions));
  }
}

import { Injectable } from '@angular/core';
import { Action, createFeatureSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '@app/core/store/store/app-store.reducer';

import { ProgramVisit } from '../shared/program-visit.type';
import {
  ProgramVisitState,
  programVisitStatePath,
} from './program-visit.reducer';

const selector = createFeatureSelector<ProgramVisitState>(
  programVisitStatePath,
);

@Injectable()
export class ProgramVisitSelectors {
  constructor(private store: Store<AppState>) {}

  get(): Observable<ProgramVisit | null> {
    return this.store.pipe(select(selector), select('programVisit'));
  }

  loading(action: Action): Observable<boolean> {
    return this.store.pipe(select(selector), select('loading', action.type));
  }
}

<div omgTheme>
  <div class="info-background"> </div>
  <div class="om-info-bar">
    <div class="section-container">
      <div class="section-wrapper">
        <ng-content select="[section=banner]"></ng-content>
      </div>
    </div>
  </div>

  <div class="om-container">
    <section scrollable
             omgTrackScroll
             trackScrollComponent="Left Column"
             class="om-section-left">
      <div class="section-container">
        <div class="section-wrapper">
          <ng-content select="[section=overview]"></ng-content>
        </div>
      </div>
    </section>

    <section [ngClass]="{'timeline-expanded' : timelineExpanded, 'workspace-expanded' : workspaceExpanded }"
             omgTrackScroll
             trackScrollComponent="Center Column"
             class="om-section-center">
      <div class="section-container">
        <div class="section-wrapper"
             sticky-card-container>
          <ng-content select="[section=workspace]"></ng-content>
        </div>
      </div>
    </section>

    <section [ngClass]="{'timeline-expanded' : timelineExpanded, 'workspace-expanded' : workspaceExpanded }"
             omgTrackScroll
             trackScrollComponent="Right Column"
             class="om-section-right">
      <div class="section-container">
        <div class="section-wrapper"
             sticky-card-container>
          <ng-content select="[section=timeline]"></ng-content>
        </div>
      </div>
    </section>
  </div>
</div>

<form [formGroup]="form.controls"
      class="om-form -immediate-errors -subform"
      (ngSubmit)="onUpdateRegimen()">

  <div *ngIf="loadingRoute$ | ngrxPush; else subForm"
       class="padding-normal">
    <ng-container *ngTemplateOutlet="loading">
    </ng-container>
  </div>

  <omg-action-bar #actionBar>
    <ng-container left-buttons>
      <div om-layout-gutter>
        <button type="submit"
                omgButton
                variant="primary"
                [disabled]="updateDisabled"
                data-cy="regimen-editor-update-btn">
          Update
        </button>
        <button type="button"
                omgButton
                variant="flat"
                (click)="onCancelRegimen()"
                data-cy="regimen-editor-cancel-btn">
          Cancel
        </button>
      </div>
    </ng-container>
  </omg-action-bar>


  <ng-template #subForm>
    <div *ngIf="form.route$ | ngrxPush"
         class="padding-normal">
      <omg-dropdown [options]="form.standardRegimenOptions"
                    *ngIf="!form.isCustomRegimen"
                    [formControl]="form.controls.get('medicationRegimenId')"
                    [flex]="true">
      </omg-dropdown>

      <div om-layout
           om-layout-fill
           om-layout-align="space-between center">
        <div om-flex="75">
          <span *ngIf="form.isCustomRegimen">{{ form.controls.get('regimenTextDescription').value }}</span>
        </div>
        <button omgButton
                variant="link"
                type="button"
                om-layout-align="end"
                *ngIf="form.customizationEnabled"
                (click)="toggleCustomRegimenEditing()"
                data-cy="medications-customize-regimen-btn">
          <span *ngIf="!form.isCustomRegimen">Customize</span>
          <span *ngIf="form.isCustomRegimen">Cancel Custom</span>
        </button>
      </div>

      <div *ngIf="form.isCustomRegimen">
        <div om-layout="vertical"
             om-layout-fill
             om-layout-gutter>
          <div class="om-flex">
            <label>Dispensed as <omg-auto-complete placeholder="Find dispensable..."
                                 [formControl]="form.controls.get('dispensableId')"
                                 [items]="form.dispensables"
                                 [hideClearAll]="true"
                                 (change)="trackDispensedAsChange($event)"
                                 (click)="trackDispensedAsClick()"
                                 resetItemsOnClear="false"
                                 trackByKey="id"
                                 bindValue="id"
                                 bindLabel="description"
                                 data-cy="regimen-editor-dispensed-as-dropdown">
              </omg-auto-complete>
            </label>
          </div>

          <omg-free-text-regimen-editor *ngIf="!!form.controls.get('useInstructionsText').value; else structuredEditing"
                                        [instructionsText]="form.controls.get('instructionsText')"
                                        [maxLength]="form.getRemainingCharacterCount()"
                                        (validate)="form.setInstructionsTextValidators($event)"
                                        (toggle)="toggleStructuredRegimenEditing()"
                                        (changeEvent)="trackFreeTextChangeEvents($event)">
          </omg-free-text-regimen-editor>
          <omg-prn-selector [usePrn]="form.controls.get('usePrn')"
                            [prnId]="form.controls.get('prnId')"
                            [prnDescription]="form.controls.get('prnDescription')"
                            (changeEvent)="trackPrnChangeEvents($event)"
                            (validate)="form.setPrnValidators()"
                            [prns]="form.prns">
          </omg-prn-selector>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #structuredEditing>
    <omg-structured-regimen-editor (toggle)="toggleStructuredRegimenEditing()"
                                   (changeEvent)="trackStructuredDataChangeEvents($event)"
                                   [form]="form.structuredRegimenForm"
                                   [dispensable]="form.dispensable"
                                   [frequencyIntervals]="form.frequencyIntervals">
    </omg-structured-regimen-editor>
  </ng-template>
</form>

<ng-template #loading
             om-layout="vertical">
  <div om-layout
       om-layout-gutter
       om-layout-align="center">
    <i class="fa fa-lg fa-spinner fa-pulse"></i>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { ToastMessageService } from '@app/shared/components/toast';
import { waitFor } from '@app/utils';

import { ChangeRxActions } from '../store/change-rx.actions';
import { ChangeRxSelectors } from '../store/change-rx.selectors';

@Injectable()
export class ChangeRxGuard implements CanActivate {
  constructor(
    private changeRxSelectors: ChangeRxSelectors,
    private toastService: ToastMessageService,
    private router: Router,
    private patientSelectors: PatientSelectors,
    private changeRxActions: ChangeRxActions,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const changeRxId = +route.paramMap.get('id');

    return waitFor(
      this.patientSelectors.patientId,
      patientId => !!patientId,
    ).pipe(
      switchMap(() => {
        this.changeRxSelectors.entities
          .pipe(
            tap(entities => {
              if (!entities?.length) {
                this.changeRxActions.load();
              }
            }),
            take(1),
          )
          .subscribe();
        return this.changeRxSelectors.loading.pipe(
          filter(loading => !loading),
          take(1),
        );
      }),
      switchMap(() =>
        this.changeRxSelectors.getById(changeRxId).pipe(
          take(1),
          map(changeRx => {
            if (!changeRx) {
              this.returnToList('The ChangeRx ID was not found');
              return false;
            }
            return true;
          }),
        ),
      ),
    );
  }

  private returnToList(errorMessage: string) {
    this.toastService.add({
      severity: 'error',
      detail: errorMessage,
    });
    this.patientSelectors.patientId
      .pipe(take(1))
      .subscribe(patientId =>
        this.router.navigateByUrl(`/patients/${patientId}/chart/renewals/list`),
      );
  }
}

import { ValidatorFn, Validators } from '@angular/forms';

import { MedicationRegimen } from '@app/modules/medications/shared';
import { MedicationDispensable } from '@app/modules/medications/shared/medications.type';
import { FocusService } from '@app/shared/directives/focus/focus.service';
import { DynamicFormGroup } from '@app/utils/forms/base';

import {
  RegimenFormControlNames,
  setRegimenFormValidator,
  validDosageValidators,
} from './utils';

export enum StructuredRegimenFormDataKeys {
  dose = RegimenFormControlNames.dose,
  durationDays = RegimenFormControlNames.durationDays,
  frequencyIntervalId = RegimenFormControlNames.frequencyIntervalId,
}

export class StructuredRegimenForm extends DynamicFormGroup {
  dispensable: MedicationDispensable;
  defaultValue: Partial<MedicationRegimen>;

  constructor(private regimen: MedicationRegimen, focusService: FocusService) {
    super(focusService);
    this.addControls();
  }

  private addControls() {
    const defaultValidators: { [key: string]: ValidatorFn[] } = {
      [RegimenFormControlNames.frequencyIntervalId]: [Validators.required],
      [RegimenFormControlNames.dose]: [
        Validators.required,
        ...validDosageValidators,
      ],
      [RegimenFormControlNames.durationDays]: [Validators.min(1)],
    };

    const controls: {
      name: string;
      validators?: ValidatorFn[];
    }[] = Object.keys(StructuredRegimenFormDataKeys).map(name => ({
      name,
      validators: defaultValidators[name],
    }));

    controls.forEach(({ name, validators }) => {
      this.addControl({
        name,
        defaultValue: this.regimen[name],
        focusOn: name === 'frequencyIntervalId',
        validators,
      });
    });
    this.defaultValue = this.controls.value;
  }

  setDurationValidators(
    durationEnabled = this.controls.get('durationDays').value,
  ) {
    const maxDurationDays =
      this.dispensable && this.dispensable.maxDurationDays;

    setRegimenFormValidator({
      control: this.controls.get('durationDays'),
      enabled: durationEnabled,
      validators: [Validators.min(1), Validators.max(maxDurationDays)],
    });
  }

  focusOnDuration() {
    this.focus('durationDays');
  }

  resetControls(enabled: boolean = false) {
    if (enabled) {
      this.controls.patchValue(this.defaultValue);
    } else {
      this.controls.patchValue({
        frequencyIntervalId: null,
        dose: null,
        durationDays: null,
      });
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { difference } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { InternalUserGraphQLService } from './internal-user-graphql.service';
import { InternalUser } from './internal-user.type';
import { InternalUsersActions } from './store/internal-users.actions';
import { InternalUsersSelectors } from './store/internal-users.selectors';

@Injectable({
  providedIn: 'root',
})
export class InternalUserInfoService implements OnDestroy {
  private pendingIds: string[];
  private request: Subject<string[]>;
  private destroy$ = new Subject<void>();

  constructor(
    private internalUserGraphQL: InternalUserGraphQLService,
    private selectors: InternalUsersSelectors,
    private actions: InternalUsersActions,
  ) {
    this.request = new Subject<string[]>();
    this.pendingIds = [];

    this.request
      .pipe(
        debounceTime(100),
        tap(() => (this.pendingIds = [])),
        this.fetchMissingInternalUserData,
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: users => this.actions.addUsers(users),
      });
  }

  get(id: string): Observable<InternalUser> {
    if (!this.pendingIds.includes(id)) {
      this.pendingIds.push(id);
      this.request.next(Array.from(this.pendingIds));
    }
    return this.selectors.get(id);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private readonly fetchMissingInternalUserData = (
    userIds$: Observable<string[]>,
  ): Observable<InternalUser[]> =>
    userIds$.pipe(
      withLatestFrom(this.selectors.getAll()),
      mergeMap(([requestedIds, storedUsers]) => {
        const storedIds = Object.keys(storedUsers);
        return of(difference(requestedIds, storedIds)).pipe(
          takeWhile(ids => ids.length > 0),
          switchMap(ids => this.internalUserGraphQL.fetch({ ids })),
          map(response => response.data.internalUsers.nodes),
        );
      }),
    );
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from '@app/core/api/api.service';

import { catchError } from 'rxjs/operators';
import { ElasticsearchLocationResponse } from './elasticsearch-location-api.type';
import { ElasticsearchLocation } from './elasticsearch-location.type';

@Injectable()
export class ElasticsearchLocationService {
  private route = '/v2/admin/elasticsearch_location';

  constructor(private api: ApiService) {}

  get(): Observable<ElasticsearchLocation> {
    return this.api
      .get<ElasticsearchLocationResponse>(this.route)
      .pipe(catchError(() => of(null)));
  }
}

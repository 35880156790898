import { Pipe, PipeTransform } from '@angular/core';

import { ordinalizePercentile } from '@app/utils';

@Pipe({
  name: 'percentile',
})
export class PercentilePipe implements PipeTransform {
  transform(value: number): string {
    return ordinalizePercentile(value);
  }
}

import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Message as PrimeNgMessage,
  MessageService as PrimeNgMessageService,
} from 'primeng/api';

export type ToastSeverity = 'info' | 'warn' | 'success' | 'error';

export interface ToastMessage extends PrimeNgMessage {
  severity?: ToastSeverity;
  summary?: string;
  detail?: string;
}

@Injectable()
export class ToastMessageService {
  constructor(
    private messageService: PrimeNgMessageService,
    private sanitizer: DomSanitizer,
  ) {}

  add(message: ToastMessage): void {
    this.messageService.add(this.sanitize(message));
  }

  sanitize(message: ToastMessage) {
    return {
      ...message,
      detail: this.sanitizeHtml(message.detail),
      summary: this.sanitizeHtml(message.summary),
    };
  }

  sanitizeHtml(html: string) {
    return html ? this.sanitizer.sanitize(SecurityContext.HTML, html) : '';
  }

  clear(key?: string): void {
    this.messageService.clear(key);
  }
}

export enum FeatureFlagNames {
  adminRfv = 'admin_rfv',
  adminUiTasks = 'admin_ui_tasks',
  alternateBookingFlowIos = 'alternate_booking_flow_ios',
  alternateBookingFlowWeb = 'alternate_booking_flow_web',
  analytics = 'analytics',
  autoCheckInCreation = 'auto_check_in_creation',
  autoDymoLabel = 'auto_dymo_label',
  autoVisitFollowUpOrderCreation = 'auto_visit_follow_up_order_creation',
  bhxProblemProgramSuggestions = 'bhx_problem_program_suggestions',
  billingEAndMUpdate = 'billing_e_and_m_update',
  billingManualHealthFusionSync = 'billing_manual_health_fusion_sync',
  billingPerformProceduresMeasurements = 'billing_perform_procedures_measurements',
  billingPerformProceduresDocuments = 'billing_perform_procedures_documents',
  billingProcedureInteractions = 'billing_procedure_interactions',
  cases = 'cases',
  ccd = 'ccd',
  chat = 'chat',
  changeMiscTaskTemplate = 'change-misc-task-template',
  clinicalTimelineShowLabOrders = 'clinical-timeline-show-lab-orders',
  clinicalTimelineHideLabOrderNotes = 'clinical-timeline-hide-lab-order-notes',
  consultOrderAutomation = 'consult_order_automation',
  insightsConnectedDeviceAuthToggle = 'insights-connected-device-authorization-toggle',
  dataPanelPilot = 'data_panel_pilot',
  deleteServiceTicketItem = 'delete_service_ticket_item',
  derm = 'derm',
  drugAllergyChecking = 'drug_allergy_checking',
  drugDrugChecking = 'drug_drug_checking',
  fontInter = 'font_inter',
  fontInterOptimal = 'font_inter_optimal',
  frontendPlatformJadeTheme = 'frontend-platform-jade-theme',
  genericFollowUpOrders = 'generic_follow_up_orders',
  healthHistory = 'health_history',
  interopManualAdtTrigger = 'interop_manual_adt_trigger',
  interopSendDmCcd = 'interop_send_dm_ccd',
  kidsAndFamilyTwoToEighteenGrowthPercentiles = 'kids_and_family_two_to_eighteen_growth_percentiles',
  kidsAndFamilyVitalsTableReorder = 'kids_and_family_vitals_table_reorder',
  kidsAndFamilyPrintableGrowthCharts = 'kids_and_family_printable_growth_charts',
  labAutoReporting = 'lab_auto_reporting',
  labsPanelFiltering = 'labs_panel_filtering',
  lipidReferenceRanges = 'lipid_reference_ranges',
  mammogramColonoscopyDataCaptureForm = 'mammogram_colonoscopy_data_capture_form',
  manualLabResultCapture = 'manual_lab_result_capture',
  medicareWellnessVisit = 'medicare_wellness_visit',
  meds1000CharInstructions = 'meds_1000_char_instructions',
  medsCancelRx = 'meds-cancel-rx',
  medsChangeRx = 'meds-change-rx',
  medsDisplayLabAutoreportabilityRules = 'meds_display_lab_autoreportability_rules',
  medsDisplayByOwnedByFrontEnd = 'meds-display-by-owned-by-frontend',
  medsPrintLabOrdersWithoutLabels = 'meds_print_lab_orders_without_labels',
  medsRxHistoryFeature = 'meds_rx_history_feature',
  medsRxRenewalRequest = 'meds_rx_renewal_request',
  medsRxChangeEditor = 'meds_rx_change_editor',
  medsRxRequirePediatricVitals = 'meds_rx_require_pediatric_vitals',
  mlMatchmaker = 'ml_matchmaker',
  mlMatchmakerProvider = 'ml_matchmaker_provider',
  mlNoteRetitling = 'ml_note_retitling',
  mobileWebEnterpriseReg = 'mobile_web_enterprise_reg',
  myOneCancellations = 'my_one_cancellations',
  new1lifeBillingUnits = 'new_1life_billing_units',
  new1lifeConsultOrders = 'new_1life_consult_orders',
  new1lifeCreateSummaryOnAppointmentBook = 'new_1life_create_summary_on_appointment_book',
  new1lifeDualPhlebWorkflow = 'new_1life_dual_phleb_workflow',
  new1lifeEnableDeletingOfficeNote = 'new_1life_enable_deleting_office_note',
  new1lifeGenerateLetter = 'new_1life_generate_letter',
  new1lifeInternalProcedureOrders = 'new_1life_internal_procedure_orders',
  new1lifeMessagingDeletion = 'new_1life_messaging_deletion',
  new1lifeProblemCardQuickOrderLinks = 'new_1life_problem_card_quick_order_links',
  new1lifeProcedureNoteIntegration = 'new_1life_procedure_note_integration',
  new1lifeVaccineOrders = 'new_1life_vaccine_orders',
  new1lifeVirtualVisitCoding = 'new_1life_virtual_visit_coding',
  new1lifeVisitCode = 'new_1life_visit_code',
  new1lifeVisitCodeAssessedProblems = 'new_1life_visit_code_assessed_problems',
  newSnippets = 'new_snippets',
  nonEssentialSidekiqJobs = 'non_essential_sidekiq_jobs',
  noteRedact = 'note_redact',
  onelifeMobileEarlyAccess = 'onelife_mobile_early_access',
  onlineBillPay = 'online_bill_pay',
  opmSiuMessage = 'opm_siu_message',
  ordersTabRedesign = 'orders-tab-redesign',
  patientCheckInModal = 'patient_check_in_modal',
  patientTimeline = 'patient_timeline',
  patientTimelineAndroid = 'patient_timeline_android',
  patientTimelineIos = 'patient_timeline_ios',
  pcpCommentByline = 'pcp-comment-byline',
  pcpCommentBoxCollapse = 'pcp-comment-box-collapse',
  pediatricsGrowthCharts = 'pediatrics_growth_charts',
  providerStats = 'provider_stats',
  raceAndEthnicityInChart = 'race_and_ethnicity_in_chart',
  registrationEmailOptOut = 'registration_email_opt_out',
  replyToCst = 'reply_to_cst',
  serviceTicketAdministrativeComments = 'service_ticket_administrative_comments',
  slashInsertionForMiscNotes = 'slash_insertion_for_misc_notes',
  sidekiqJobs = 'sidekiq_jobs',
  supportingDocuments = 'supporting_documents',
  surescriptsEnabled = 'surescripts_enabled',
  unifiedTemplateManager = 'unified_template_manager',
  vaccineOrderDeleteAndEdit = 'vaccine_order_delete_and_edit',
  vaccineAddMultiStepHistory = 'vaccine-add-multi-step-history',
  video = 'video',
  helpOut = 'help_out',
  connectedDeviceOrders = 'orders-connected-device-orders',
  userInfoPopover = 'user-info-popover',
}

type ValueOf<T> = T[keyof T];
export type FeatureFlag = ValueOf<FeatureFlagNames>;

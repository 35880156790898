import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

import { loginPath } from './auth-constants';

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(private auth: AuthService) {}

  canActivate() {
    const returnTo = `${window.location.origin}/${loginPath}`;
    this.auth.logout({ returnTo: returnTo });
    return true;
  }
}

import { Injectable } from '@angular/core';
import { FsaAction } from '@app/core';
import { Store } from '@ngrx/store';

import { FeatureFlag } from '../shared/feature-flag.type';
import { FeatureFlagState } from '../store/feature-flag.reducer';

export const GET_FEATURE_FLAGS = '[FeatureFlags] Get Feature Flags';
export const GET_FEATURE_FLAGS_SUCCESS =
  '[FeatureFlags] Get Feature Flags Success';
export const GET_FEATURE_FLAGS_ERROR = '[FeatureFlags] Get Feature Flags Error';

type FeatureFlagsError = any;

export class GetFeatureFlags implements FsaAction<void> {
  readonly type = GET_FEATURE_FLAGS;
}

export class GetFeatureFlagsSuccess implements FsaAction<FeatureFlag[]> {
  readonly type = GET_FEATURE_FLAGS_SUCCESS;

  constructor(public payload: FeatureFlag[]) {}
}

export class GetFeatureFlagsError implements FsaAction<any> {
  readonly type = GET_FEATURE_FLAGS_ERROR;
  error = true;

  constructor(public payload: FeatureFlagsError) {}
}

export type FeatureFlagAction =
  | GetFeatureFlags
  | GetFeatureFlagsSuccess
  | GetFeatureFlagsError;

@Injectable()
export class FeatureFlagActions {
  constructor(private store: Store<FeatureFlagState>) {}

  getFeatureFlags() {
    this.store.dispatch(new GetFeatureFlags());
  }
}

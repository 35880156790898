import { sortBy } from 'lodash/fp';

import { mapMedicationRegimenToForm } from './medications-utils';
import { newEntityId } from './medications.type';
import {
  PatientMedication,
  PatientMedicationForm,
} from './patient-medications.type';

export const buildNewPatientMedicationForm = (
  partialForm: Partial<PatientMedicationForm> = {},
): PatientMedicationForm => ({
  id: newEntityId,
  patientId: null,
  medicationRouteId: null,
  medicationRegimenId: null,
  isCustomRegimen: false,
  dispensableId: null,
  prescriptionTemplateId: null,
  prescriptionTemplateItemId: null,
  dose: null,
  durationDays: null,
  frequencyIntervalId: null,
  instructionsText: null,
  useInstructionsText: false,
  prnId: null,
  prnDescription: null,
  usePrn: false,
  ...partialForm,
});

export const mapPatientMedicationToForm = (
  data: PatientMedication,
): PatientMedicationForm => ({
  id: data.id,
  patientId: null,
  ...mapMedicationRegimenToForm(data.latestRegimen),
});

export const clinicalSort = (medications: PatientMedication[]) =>
  sortBy(medication => {
    let duration = 100;
    let isPrn = 0;

    if (medication.latestPatientMedicationRegimen) {
      const latestRegimen = medication.latestPatientMedicationRegimen;
      if (latestRegimen.discontinuesAt) {
        duration = 0;
      }
      if (latestRegimen.isPrn) {
        isPrn = 1000;
      }
    }

    if (duration && isPrn) {
      duration = 0;
    }

    const routeName = medication.route.name;
    const firstCharacterCode = routeName.toLowerCase().charCodeAt(0);
    const lowerCaseA = 97;
    const alphabeticalDelta = firstCharacterCode - lowerCaseA;

    return duration + isPrn + alphabeticalDelta;
  }, medications);

export const getSortedActivePatientMedications = (
  patientMedications: PatientMedication[],
) => {
  const medications = patientMedications.filter(
    medication => medication.active,
  );

  return clinicalSort(medications);
};

export const getSortedInactivePatientMedications = (
  patientMedications: PatientMedication[],
) => {
  const medications = patientMedications.filter(
    medication => !medication.active,
  );
  return clinicalSort(medications);
};

export const partitionMedications = (
  patientMedications: PatientMedication[] = [],
) => {
  const active = getSortedActivePatientMedications(patientMedications);
  const inactive = getSortedInactivePatientMedications(patientMedications);
  return { active, inactive };
};

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';

import { HelpRequestForm } from './help-request-form';
import { HelpRequestService } from './help-request.service';

@Injectable()
export class HelpRequestFormService {
  constructor(
    private helpRequestService: HelpRequestService,
    private patientSelectors: PatientSelectors,
  ) {}

  buildForm(unsubscribe$: Subject<void>) {
    return new HelpRequestForm(
      this.helpRequestService,
      this.patientSelectors,
      unsubscribe$,
    );
  }
}

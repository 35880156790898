import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  MessagingAction,
  MessagingActionTypes,
} from './../../messaging/store/messaging.actions';
import { Note } from '../shared/note.type';
import { NoteAction, NoteActionTypes } from './note.actions';

export const noteStatePath = 'notes';

export interface NoteState extends EntityState<Note> {
  pending: boolean;
  loadingPDF: boolean;
  composeMessagePending: boolean;
  error: any;
  currentNoteId: number;
}

export function selectNoteId(note: Note): number {
  return note.id;
}

export const adapter: EntityAdapter<Note> = createEntityAdapter<Note>({
  selectId: selectNoteId,
});

export const initialNoteState: NoteState = adapter.getInitialState({
  pending: false,
  loadingPDF: false,
  error: null,
  currentNoteId: null,
  composeMessagePending: false,
});

export function noteReducer(
  state = initialNoteState,
  action: NoteAction | MessagingAction,
): NoteState {
  switch (action.type) {
    case NoteActionTypes.LOAD_NOTE: {
      return { ...state, error: null, pending: true, loadingPDF: false };
    }

    case NoteActionTypes.LOAD_NOTE_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        error: null,
        pending: false,
        currentNoteId: action.payload.id,
      });
    }

    case NoteActionTypes.LOAD_NOTE_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case NoteActionTypes.LOAD_TEMPLATE: {
      return { ...state, error: null, pending: true };
    }

    case NoteActionTypes.LOAD_TEMPLATE_ERROR: {
      const actionError = { ...state, error: action.payload, pending: false };
      return actionError;
    }

    case NoteActionTypes.LOAD_NOTE_WITH_TODO: {
      return { ...state, error: null, pending: true, loadingPDF: false };
    }

    case NoteActionTypes.LOAD_NOTE_WITH_TODO_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        error: null,
        pending: false,
        currentNoteId: action.payload.id,
      });
    }

    case NoteActionTypes.LOAD_NOTE_WITH_TODO_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case NoteActionTypes.SAVE_NOTE: {
      return { ...state, error: null, pending: true };
    }

    case NoteActionTypes.SAVE_NOTE_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case NoteActionTypes.SAVE_NOTE_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case NoteActionTypes.UPDATE_NOTE: {
      return { ...state, error: null, pending: true };
    }

    case NoteActionTypes.UPDATE_NOTE_SUCCESS: {
      // update the note in the store without trampling its todo/todoId
      const existingNote = state.entities[action.payload.id];
      const newNote = {
        ...action.payload,
        todoId: existingNote?.todoId,
        todo: existingNote?.todo,
      };
      return adapter.upsertOne(newNote, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case NoteActionTypes.UPDATE_NOTE_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case NoteActionTypes.CLONE_NOTE: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case NoteActionTypes.CLONE_NOTE_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case NoteActionTypes.CLONE_NOTE_ERROR: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    case NoteActionTypes.DELETE_NOTE: {
      return { ...state, error: null, pending: true };
    }

    case NoteActionTypes.DELETE_NOTE_SUCCESS: {
      return adapter.removeOne(action.payload, {
        ...state,
        error: null,
        pending: false,
        currentNoteId: null,
      });
    }

    case NoteActionTypes.DELETE_NOTE_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case NoteActionTypes.NEW_POST_FROM_NOTE: {
      return {
        ...state,
        error: null,
        pending: true,
        composeMessagePending: true,
      };
    }

    case MessagingActionTypes.SET_CURRENT_POST: {
      return { ...state, composeMessagePending: false };
    }

    case NoteActionTypes.NEW_POST_FROM_NOTE_SUCCESS: {
      return { ...state, error: null, pending: false };
    }

    case NoteActionTypes.NEW_POST_FROM_NOTE_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case NoteActionTypes.RECATEGORIZE_NOTE: {
      const noteId = action.payload.noteId || state.currentNoteId;
      action.payload = { ...state.entities[noteId], ...action.payload };

      return { ...state, error: null, pending: true };
    }

    case NoteActionTypes.RECATEGORIZE_NOTE_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case NoteActionTypes.RECATEGORIZE_NOTE_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case NoteActionTypes.REDACT_NOTE: {
      return { ...state, error: null, pending: true };
    }

    case NoteActionTypes.REDACT_NOTE_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case NoteActionTypes.REDACT_NOTE_ERROR: {
      return { ...state, error: true, pending: false };
    }

    case NoteActionTypes.GENERATE_LETTER: {
      return { ...state, loadingPDF: true, error: null };
    }

    case NoteActionTypes.GENERATE_LETTER_SUCCESS: {
      return { ...state, loadingPDF: false, error: null };
    }

    case NoteActionTypes.GENERATE_LETTER_ERROR: {
      return { ...state, loadingPDF: false, error: action.payload };
    }

    case NoteActionTypes.ADD_COMMENT: {
      const note = state.entities[action.note.id];
      const newNote = {
        ...note,
        totalComments: note.totalComments + 1,
      };
      return adapter.upsertOne(newNote, { ...state });
    }

    case NoteActionTypes.REMOVE_COMMENT: {
      const note = state.entities[action.note.id];
      const newNote = {
        ...note,
        totalComments: note.totalComments - 1,
      };
      return adapter.upsertOne(newNote, { ...state });
    }

    default:
      return { ...state };
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'omgEmphasize' })
export class EmphasizePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, value: string) {
    if (text && text.includes(value)) {
      return this.sanitizer.bypassSecurityTrustHtml(
        text.replace(value, `<strong>${value}</strong>`),
      );
    }
    return text;
  }
}

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import { DropdownItem } from '@app/shared';

@Component({
  selector: 'omg-rx-item-sign-and-dea',
  templateUrl: './rx-item-sign-and-dea.component.html',
  styleUrls: ['./rx-item-sign-and-dea.component.css'],
})
export class RxItemSignAndDeaComponent {
  @Input() loading$: Observable<boolean>;

  @Input() prescriber: Prescriber;
  @Input() validPrescribers: Prescriber[];
  @Input() credentialItems$: Observable<DropdownItem[]>;

  @Input() prescriberId: FormControl;
  @Input() prescribingCredentialId: FormControl;

  constructor() {}
}

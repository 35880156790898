import { TodoReassignmentResponse } from '@app/modules/messaging/shared/todo-reassignment-api.type';
import { TodoReassignment } from '@app/modules/messaging/shared/todo-reassignment.service';
import { get } from '@app/utils';

export function mapResponseToTodoReassignment(
  response: TodoReassignmentResponse,
): TodoReassignment {
  const reassignment = response.patients[0].posts.nodes[0].reassignment;
  if (!reassignment) {
    return null;
  }

  const newAssigneeName = get('newAssignee.displayName', reassignment);
  const originalAssigneeName = get(
    'originalAssignee.displayName',
    reassignment,
  );
  const labels = get('labels', reassignment);
  const id = get('id', reassignment);
  const feedbackExists = !!get('feedback.id', reassignment);
  const feedbackHasNewAssignee = !!get('feedback.newAssignee', reassignment);
  return {
    id,
    newAssigneeName,
    originalAssigneeName,
    labels,
    feedbackExists,
    feedbackHasNewAssignee,
  };
}

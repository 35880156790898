import { Injectable } from '@angular/core';
import { FsaAction } from '@app/core';
import { Store } from '@ngrx/store';

import { CorrelationId, uuid } from '@app/utils';
import { RxCart } from '../shared/rx-cart.type';
import { RxCartState } from './rx-cart.reducer';

export const LOAD_RX_CART = '[RxCart] Load Cart';
export const LOAD_RX_CART_SUCCESS = '[RxCart] Load Cart Success';
export const LOAD_RX_CART_ERROR = '[RxCart] Load Cart Error';
export const UPDATE_RX_CART = '[RxCart] Update Cart';
export const UPDATE_RX_CART_SUCCESS = '[RxCart] Update Cart Success';
export const UPDATE_RX_CART_ERROR = '[RxCart] Update Cart Error';
export const REFRESH_RX_CART = '[RxCart] Refresh Cart';
export const REFRESH_RX_CART_SUCCESS = '[RxCart] Refresh Cart Success';
export const REFRESH_RX_CART_ERROR = '[RxCart] Refresh Cart Error';
export const COMPLETE_RX_CART = '[RxCart] Complete Cart';
export const SET_RX_CART_ATTRIBUTES = '[RxCart] Set Cart Attributes';
export const SET_RX_CART_PHARMACY_ID = '[RxCart] Set Cart Pharmacy Id';

type RxCartErrorPayload = any;
type CartId = number;

export class LoadRxCart implements FsaAction<void> {
  readonly type = LOAD_RX_CART;
}

export class LoadRxCartSuccess implements FsaAction<RxCart> {
  readonly type = LOAD_RX_CART_SUCCESS;
  error = false;

  constructor(public payload: RxCart) {}
}

export class LoadRxCartError implements FsaAction<any> {
  readonly type = LOAD_RX_CART_ERROR;
  error = true;

  constructor(public payload: RxCartErrorPayload) {}
}

export class UpdateRxCart implements FsaAction<RxCart> {
  readonly type = UPDATE_RX_CART;

  constructor(
    public payload: RxCart,
    public meta: { correlationId: CorrelationId },
  ) {}
}

export class UpdateRxCartSuccess implements FsaAction<RxCart> {
  readonly type = UPDATE_RX_CART_SUCCESS;
  error = false;

  constructor(
    public payload: RxCart,
    public meta: { correlationId: CorrelationId },
  ) {}
}

export class UpdateRxCartError implements FsaAction<RxCartErrorPayload> {
  readonly type = UPDATE_RX_CART_ERROR;
  error = true;

  constructor(public payload: RxCartErrorPayload) {}
}

export class RefreshRxCart implements FsaAction<CartId> {
  readonly type = REFRESH_RX_CART;

  constructor(public payload: CartId) {}
}

export class RefreshRxCartSuccess implements FsaAction<RxCart> {
  readonly type = REFRESH_RX_CART_SUCCESS;
  error = false;

  constructor(public payload: RxCart) {}
}

export class RefreshRxCartError implements FsaAction<RxCartErrorPayload> {
  readonly type = REFRESH_RX_CART_ERROR;
  error = true;

  constructor(public payload: RxCartErrorPayload) {}
}

type PatientId = number;

export class CompleteCart implements FsaAction<PatientId> {
  readonly type = COMPLETE_RX_CART;

  constructor(public payload: PatientId) {}
}

export class SetRxCartAttributes implements FsaAction<Partial<RxCart>> {
  readonly type = SET_RX_CART_ATTRIBUTES;

  constructor(public payload: Partial<RxCart>) {}
}

export class SetRxCartPharmacyId implements FsaAction<number> {
  readonly type = SET_RX_CART_PHARMACY_ID;

  constructor(public payload: number) {}
}

export type RxCartAction =
  | LoadRxCart
  | LoadRxCartSuccess
  | LoadRxCartError
  | UpdateRxCart
  | UpdateRxCartSuccess
  | UpdateRxCartError
  | RefreshRxCart
  | RefreshRxCartSuccess
  | RefreshRxCartError
  | CompleteCart
  | SetRxCartAttributes
  | SetRxCartPharmacyId;

@Injectable({
  providedIn: 'root',
})
export class RxCartActions {
  constructor(private store: Store<RxCartState>) {}

  loadRxCart() {
    this.store.dispatch(new LoadRxCart());
  }

  updateRxCart(cart: RxCart, correlationId?: CorrelationId) {
    correlationId = correlationId || uuid();
    this.store.dispatch(new UpdateRxCart(cart, { correlationId }));

    return correlationId;
  }

  refreshRxCart(cartId: number) {
    this.store.dispatch(new RefreshRxCart(cartId));
  }

  completeRxCart(cart: RxCart) {
    this.store.dispatch(new CompleteCart(cart.patientId));
  }

  setRxCartAttributes(cart: Partial<RxCart>) {
    this.store.dispatch(new SetRxCartAttributes(cart));
  }

  setRxCartPharmacyId(pharmacyId: number) {
    this.store.dispatch(new SetRxCartPharmacyId(pharmacyId));
  }
}

import { Component, Input } from '@angular/core';

import { PopoverPlacement } from '@app/shared/components/popover/popover-config';

@Component({
  selector: 'omg-internal-user-info',
  templateUrl: './internal-user-info.component.html',
})
export class InternalUserInfoComponent {
  @Input() providerId: number;
  @Input() placement: PopoverPlacement = 'right';
}

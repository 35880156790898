import { Action, createReducer, on } from '@ngrx/store';

import { cloneDeep } from '@app/utils';

import { ProgramVisit } from '../shared/program-visit.type';
import {
  getProgramVisitBySummary,
  getProgramVisitBySummaryError,
  getProgramVisitBySummarySuccess,
  updateProgramForAppointment,
  updateProgramForAppointmentError,
  updateProgramForAppointmentSuccess,
  updateProgramVisit,
  updateProgramVisitCarePlan,
  updateProgramVisitCarePlanError,
  updateProgramVisitCarePlanSuccess,
  updateProgramVisitError,
  updateProgramVisitSuccess,
} from './program-visit.actions';

export const programVisitStatePath = 'programVisit';

export interface ProgramVisitState {
  programVisit?: ProgramVisit;
  loading: { [key: string]: boolean };
  errors: { [key: string]: any };
}

export const initialState: ProgramVisitState = {
  programVisit: null,
  loading: {},
  errors: {},
};

const setLoading = <T extends { loading: { [key: string]: boolean } }>(
  state: T,
  action: Action,
  loading: boolean,
) => ({ ...state.loading, [action.type]: loading });

const setError = <T extends { errors: { [key: string]: any } }>(
  state: T,
  action: Action,
  error: any,
) => ({ ...state.errors, [action.type]: error });

export const programVisitReducer = createReducer(
  initialState,
  on(getProgramVisitBySummary, state => ({
    ...state,
    loading: setLoading(state, getProgramVisitBySummary, true),
    errors: setError(state, getProgramVisitBySummary, null),
  })),
  on(getProgramVisitBySummarySuccess, (state, action) => ({
    ...state,
    programVisit: action.programVisit,
    loading: setLoading(state, getProgramVisitBySummary, false),
    errors: setError(state, getProgramVisitBySummary, null),
  })),
  on(getProgramVisitBySummaryError, (state, action) => ({
    ...state,
    loading: setLoading(state, getProgramVisitBySummary, false),
    errors: setError(state, getProgramVisitBySummary, action.error),
  })),
  on(updateProgramVisitCarePlan, (state, action) => ({
    ...state,
    loading: setLoading(state, updateProgramVisitCarePlan, true),
    errors: setError(state, updateProgramVisitCarePlan, null),
  })),
  on(updateProgramVisitCarePlanSuccess, (state, action) => {
    const newState = cloneDeep(state);

    if (
      newState?.programVisit?.summary?.appointment?.programEnrollment
        ?.programCarePlan
    ) {
      newState.programVisit.summary.appointment.programEnrollment.programCarePlan =
        action.programCarePlan;
    }

    return {
      ...newState,
      loading: setLoading(state, updateProgramVisitCarePlan, false),
      errors: setError(state, updateProgramVisitCarePlan, null),
    };
  }),
  on(updateProgramVisitCarePlanError, (state, action) => ({
    ...state,
    loading: setLoading(state, updateProgramVisitCarePlan, false),
    errors: setError(state, updateProgramVisitCarePlan, action.error),
  })),
  on(updateProgramForAppointment, (state, action) => ({
    ...state,
    loading: setLoading(state, updateProgramForAppointment, true),
    errors: setError(state, updateProgramForAppointment, null),
  })),
  on(updateProgramForAppointmentSuccess, (state, action) => {
    const newState = cloneDeep(state);

    if (newState?.programVisit?.summary?.appointment?.programEnrollment) {
      newState.programVisit.summary.appointment.programEnrollment =
        action.programEnrollment;
    }

    return {
      ...newState,
      loading: setLoading(state, updateProgramForAppointment, false),
      errors: setError(state, updateProgramForAppointment, null),
    };
  }),
  on(updateProgramForAppointmentError, (state, action) => ({
    ...state,
    loading: setLoading(state, updateProgramForAppointment, false),
    errors: setError(state, updateProgramForAppointment, action.error),
  })),
  on(updateProgramVisit, state => ({
    ...state,
    loading: setLoading(state, updateProgramVisit, true),
    errors: setError(state, updateProgramVisit, null),
  })),
  on(updateProgramVisitSuccess, (state, action) => ({
    ...state,
    programVisit: action.programVisit,
    loading: setLoading(state, updateProgramVisit, false),
    errors: setError(state, updateProgramVisit, null),
  })),
  on(updateProgramVisitError, (state, action) => ({
    ...state,
    loading: setLoading(state, updateProgramVisit, false),
    errors: setError(state, updateProgramVisit, action.error),
  })),
);

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ReferenceDataKeys,
  ReferenceDataSelectors,
} from '@app/modules/reference-data';

import { ChangeRxForm } from '../../shared/change-rx-form';
import { NUCCProviderSpecialty } from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-provider-auth',
  templateUrl: './change-rx-provider-auth.component.html',
  styleUrls: ['./change-rx-provider-auth.component.scss'],
})
export class ChangeRxProviderAuthComponent implements OnInit {
  @Input() form: ChangeRxForm;

  nuccProviderSpecialties$: Observable<NUCCProviderSpecialty>;
  providerNPIOptions = [];

  constructor(private referenceDataSelectors: ReferenceDataSelectors) {}

  ngOnInit(): void {
    this.nuccProviderSpecialties$ = this.referenceDataSelectors.get(
      ReferenceDataKeys.nuccProviderSpecialties,
    );
    this.setupSignedOnBehalfOfOptions();
  }

  private setupSignedOnBehalfOfOptions() {
    const {
      signedOnBehalfOfName,
      signedOnBehalfOfNpi,
    } = this.form.changeRx.originalMedicationPrescription.cartSource;

    const signedOnBehalfOfInfo = {
      provider: `${signedOnBehalfOfName}, ${signedOnBehalfOfNpi}`,
    };
    this.providerNPIOptions.push(signedOnBehalfOfInfo);
  }
}

import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

import { ButtonComponent } from '@app/shared/components/button/button.component';

import { ReadyForCheckoutValidator } from '../../shared/renewal-utils';
import { Renewal, RenewalCartState } from '../../shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-ready-for-checkout-item',
  templateUrl: './ready-for-checkout-item.component.html',
})
export class ReadyForCheckoutItemComponent implements OnInit, AfterViewInit {
  @Input() renewal: Renewal;
  @Input() focus = false;
  @ViewChild(ButtonComponent) undoButton: ButtonComponent;

  isDenied: boolean;
  title: string;
  updateMessage: string;
  isValid$: Observable<boolean>;
  canSignOnBehalfOf$: Observable<boolean>;
  loading$: Observable<boolean>;

  constructor(
    private validator: ReadyForCheckoutValidator,
    private renewalSelectors: RenewalSelectors,
    private renewalActions: RenewalActions,
  ) {}

  ngAfterViewInit(): void {
    if (this.focus) {
      this.undoButton.focus();
    }
  }

  ngOnInit() {
    this.isDenied = this.renewal.cartState === RenewalCartState.denied;
    this.title = this.getRenewalTitle();
    this.updateMessage = this.getRenewalUpdateMessage();
    this.setRequireUndoBeforeCheckout();
    this.loading$ = this.renewalSelectors.loading;
  }

  undo() {
    const changes = {
      id: this.renewal.id,
      cartState: RenewalCartState.pending,
      denialReason: { code: null, description: null },
      className: this.renewal.className,
    };
    this.renewalActions.update({ id: this.renewal.id, changes });
  }

  get errorMessage() {
    const prescriberName =
      this.renewal.prescriber && this.renewal.prescriber.name;
    const existingPrescriberMessage = prescriberName
      ? `by ${prescriberName} `
      : '';
    return `This request has been processed ${existingPrescriberMessage}and cannot be checked out. Please undo and sign on behalf of yourself.`;
  }

  private getRenewalTitle() {
    return `${this.renewal.medForDisplay.name} ${this.renewal.medForDisplay.dispensableTextDesc}`;
  }

  private getRenewalUpdateMessage() {
    return `will ${this.isDenied ? 'not ' : ''}be renewed upon checkout`;
  }

  private setRequireUndoBeforeCheckout() {
    this.isValid$ = this.validator.isValid(this.renewal);
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import {
  ReferenceDataErrorPayload,
  ReferenceDataKeys,
} from '@app/modules/reference-data/shared/reference-data.type';

import { ReferenceDataState } from './reference-data.reducer';

export enum ReferenceDataActionTypes {
  LoadReferenceDataActionType = '[Reference Data] Load Reference Data',
  LoadReferenceDataActionSuccessType = '[Reference Data] Load Reference Data Success',
  LoadReferenceDataActionErrorType = '[Reference Data] Load Reference Data Error',
}

export class LoadReferenceData implements FsaAction<ReferenceDataKeys> {
  public readonly type = ReferenceDataActionTypes.LoadReferenceDataActionType;

  constructor(public referenceDataKey: ReferenceDataKeys) {}
}

export class LoadReferenceDataSuccess implements FsaAction<any> {
  readonly type = ReferenceDataActionTypes.LoadReferenceDataActionSuccessType;

  constructor(
    public payload: any,
    public referenceDataKey: ReferenceDataKeys,
  ) {}
}

export class LoadReferenceDataError
  implements FsaAction<ReferenceDataErrorPayload> {
  readonly type = ReferenceDataActionTypes.LoadReferenceDataActionErrorType;

  constructor(
    public payload: ReferenceDataErrorPayload,
    public referenceDataKey: ReferenceDataKeys,
  ) {}
}

export type ReferenceDataAction =
  | LoadReferenceData
  | LoadReferenceDataSuccess
  | LoadReferenceDataError;

@Injectable()
export class ReferenceDataActions {
  constructor(private store: Store<ReferenceDataState>) {}

  loadReferenceData(referenceDataKey: ReferenceDataKeys) {
    this.store.dispatch(new LoadReferenceData(referenceDataKey));
  }
}

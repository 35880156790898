import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core/store/shared/fsa-action';
import { Order, OrderTypes } from '@app/modules/orders/shared/order.type';

import { Comment } from '../shared/comments.type';
import { OrderCommentsState } from './order-comments.reducer';

export enum OrderCommentsActionTypes {
  GetOrderComments = '[Comments] Get Order Comments',
  GetOrderCommentsSuccess = '[Comments] Get Order Comments Success',
  GetOrderCommentsError = '[Comments] Get Order Comments Error',
  CreateOrderComment = '[Comments] Create Order Comments',
  CreateOrderCommentSuccess = '[Comments] Create Order Comments Success',
  CreateOrderCommentError = '[Comments] Create Order Comments Error',
  DeleteOrderComment = '[Comments] Delete Order Comments',
  DeleteOrderCommentSuccess = '[Comments] Delete Order Comments Success',
  DeleteOrderCommentError = '[Comments] Delete Order Comments Error',
}

type OrderCommentsErrorData = any;

interface CreateOrderCommentData {
  commentableId: number;
  commentableType: OrderTypes;
  commentBody: string;
}

export class GetOrderComments implements FsaAction<Partial<Order>> {
  readonly type = OrderCommentsActionTypes.GetOrderComments;
  error = false;

  constructor(public payload: Partial<Order>) {}
}

export class GetOrderCommentsSucccess implements FsaAction<Comment[]> {
  readonly type = OrderCommentsActionTypes.GetOrderCommentsSuccess;
  error = false;

  constructor(public payload: Comment[]) {}
}

export class GetOrderCommentsError
  implements FsaAction<OrderCommentsErrorData> {
  readonly type = OrderCommentsActionTypes.GetOrderCommentsError;
  error = true;

  constructor(public payload: OrderCommentsErrorData) {}
}

export class CreateOrderComment implements FsaAction<CreateOrderCommentData> {
  readonly type = OrderCommentsActionTypes.CreateOrderComment;
  error = false;

  constructor(public payload: CreateOrderCommentData) {}
}

export class CreateOrderCommentSuccess implements FsaAction<Comment> {
  readonly type = OrderCommentsActionTypes.CreateOrderCommentSuccess;
  error = false;

  constructor(public payload: Comment) {}
}

export class CreateOrderCommentError
  implements FsaAction<OrderCommentsErrorData> {
  readonly type = OrderCommentsActionTypes.CreateOrderCommentError;
  error = true;

  constructor(public payload: OrderCommentsErrorData) {}
}

export class DeleteOrderComment implements FsaAction<number> {
  readonly type = OrderCommentsActionTypes.DeleteOrderComment;
  error = false;

  constructor(public payload: number) {}
}

export class DeleteOrderCommentSuccess implements FsaAction<number> {
  readonly type = OrderCommentsActionTypes.DeleteOrderCommentSuccess;
  error = false;

  constructor(public payload: number) {}
}

export class DeleteOrderCommentError
  implements FsaAction<OrderCommentsErrorData> {
  readonly type = OrderCommentsActionTypes.DeleteOrderCommentError;
  error = true;

  constructor(public payload: OrderCommentsErrorData) {}
}

export type OrderCommentsAction =
  | GetOrderComments
  | GetOrderCommentsSucccess
  | GetOrderCommentsError
  | CreateOrderComment
  | CreateOrderCommentSuccess
  | CreateOrderCommentError;

@Injectable()
export class OrderCommentsActions {
  constructor(private store: Store<OrderCommentsState>) {}

  getComments(order: Partial<Order>) {
    this.store.dispatch(new GetOrderComments(order));
  }

  createComment(
    commentableId: number,
    commentableType: OrderTypes,
    commentBody: string,
  ) {
    this.store.dispatch(
      new CreateOrderComment({
        commentableId,
        commentableType,
        commentBody,
      }),
    );
  }

  deleteComment(commentId: number) {
    this.store.dispatch(new DeleteOrderComment(commentId));
  }
}

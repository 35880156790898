import { Action, createReducer, on } from '@ngrx/store';

import { ProcedureInteraction } from '@app/features/procedure-interaction/shared/procedure-interaction.type';
import {
  attachTimelineItemToProcedureInteraction,
  attachTimelineItemToProcedureInteractionError,
  attachTimelineItemToProcedureInteractionSuccess,
  clearCompleteProcedureInteractionError,
  completeProcedureInteraction,
  completeProcedureInteractionError,
  completeProcedureInteractionSuccess,
  createProcedureInteraction,
  createProcedureInteractionError,
  createProcedureInteractionIndication,
  createProcedureInteractionIndicationError,
  createProcedureInteractionIndicationSuccess,
  createProcedureInteractionSuccess,
  deleteProcedureInteraction,
  deleteProcedureInteractionError,
  deleteProcedureInteractionIndication,
  deleteProcedureInteractionIndicationError,
  deleteProcedureInteractionIndicationSuccess,
  deleteProcedureInteractionSuccess,
  deleteResultDocument,
  deleteResultDocumentError,
  deleteResultDocumentSuccess,
  getProcedureInteraction,
  getProcedureInteractionError,
  getProcedureInteractionSuccess,
  recordProcedureInteractionMeasurement,
  recordProcedureInteractionMeasurementError,
  recordProcedureInteractionMeasurementSuccess,
  updateProcedureInteraction,
  updateProcedureInteractionError,
  updateProcedureInteractionSuccess,
} from '@app/features/procedure-interaction/store/procedure-interaction.actions';

import {
  addResultDocuments,
  addResultDocumentsError,
  addResultDocumentsSuccess,
} from './procedure-interaction.actions';

export const procedureInteractionStatePath = 'procedureInteraction';

export interface ProcedureInteractionState {
  procedureInteraction?: ProcedureInteraction;
  loading: { [key: string]: boolean };
  errors: { [key: string]: any };
}

export const initialState: ProcedureInteractionState = {
  procedureInteraction: null,
  loading: {},
  errors: {},
};

const setLoading = <T extends { loading: { [key: string]: boolean } }>(
  state: T,
  action: Action,
  loading: boolean,
) => ({ ...state.loading, [action.type]: loading });

const setError = <T extends { errors: { [key: string]: any } }>(
  state: T,
  action: Action,
  error: any,
) => ({ ...state.errors, [action.type]: error });

export const procedureInteractionReducer = createReducer(
  initialState,
  on(createProcedureInteraction, (state, action) => ({
    ...state,
    loading: setLoading(state, createProcedureInteraction, true),
    errors: setError(state, createProcedureInteraction, null),
  })),
  on(createProcedureInteractionSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, createProcedureInteraction, false),
    errors: setError(state, createProcedureInteraction, null),
  })),
  on(createProcedureInteractionError, (state, action) => ({
    ...state,
    loading: setLoading(state, createProcedureInteraction, false),
    errors: setError(state, createProcedureInteraction, action.error),
  })),
  on(updateProcedureInteraction, (state, action) => ({
    ...state,
    loading: setLoading(state, updateProcedureInteraction, true),
    errors: setError(state, updateProcedureInteraction, null),
  })),
  on(updateProcedureInteractionSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, updateProcedureInteraction, false),
    errors: setError(state, updateProcedureInteraction, null),
  })),
  on(updateProcedureInteractionError, (state, action) => ({
    ...state,
    loading: setLoading(state, updateProcedureInteraction, false),
    errors: setError(state, updateProcedureInteraction, action.error),
  })),
  on(getProcedureInteraction, (state, action) => ({
    ...state,
    loading: setLoading(state, getProcedureInteraction, true),
    errors: setError(state, getProcedureInteraction, null),
  })),
  on(getProcedureInteractionSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, getProcedureInteraction, false),
    errors: setError(state, getProcedureInteraction, null),
  })),
  on(getProcedureInteractionError, (state, action) => ({
    ...state,
    loading: setLoading(state, getProcedureInteraction, false),
    errors: setError(state, getProcedureInteraction, action.error),
  })),
  on(completeProcedureInteraction, (state, action) => ({
    ...state,
    loading: setLoading(state, completeProcedureInteraction, true),
    errors: setError(state, completeProcedureInteraction, null),
  })),
  on(completeProcedureInteractionSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, completeProcedureInteraction, false),
    errors: setError(state, completeProcedureInteraction, null),
  })),
  on(completeProcedureInteractionError, (state, action) => ({
    ...state,
    loading: setLoading(state, completeProcedureInteraction, false),
    errors: setError(state, completeProcedureInteraction, action.error),
  })),
  on(clearCompleteProcedureInteractionError, state => ({
    ...state,
    errors: setError(state, completeProcedureInteraction, null),
  })),
  on(deleteProcedureInteraction, (state, action) => ({
    ...state,
    loading: setLoading(state, deleteProcedureInteraction, true),
    errors: setError(state, deleteProcedureInteraction, null),
  })),
  on(deleteProcedureInteractionSuccess, state => ({
    ...state,
    procedureInteraction: null,
    loading: setLoading(state, deleteProcedureInteraction, false),
    errors: setError(state, deleteProcedureInteraction, null),
  })),
  on(deleteProcedureInteractionError, (state, action) => ({
    ...state,
    loading: setLoading(state, deleteProcedureInteraction, false),
    errors: setError(state, deleteProcedureInteraction, action.error),
  })),
  on(createProcedureInteractionIndication, (state, action) => ({
    ...state,
    loading: setLoading(state, createProcedureInteractionIndication, true),
    errors: setError(state, createProcedureInteractionIndication, null),
  })),
  on(createProcedureInteractionIndicationSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, createProcedureInteractionIndication, false),
    errors: setError(state, createProcedureInteractionIndication, null),
  })),
  on(createProcedureInteractionIndicationError, (state, action) => ({
    ...state,
    loading: setLoading(state, createProcedureInteractionIndication, false),
    errors: setError(state, createProcedureInteractionIndication, action.error),
  })),
  on(deleteProcedureInteractionIndication, (state, action) => ({
    ...state,
    loading: setLoading(state, deleteProcedureInteractionIndication, true),
    errors: setError(state, deleteProcedureInteractionIndication, null),
  })),
  on(deleteProcedureInteractionIndicationSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, deleteProcedureInteractionIndication, false),
    errors: setError(state, deleteProcedureInteractionIndication, null),
  })),
  on(deleteProcedureInteractionIndicationError, (state, action) => ({
    ...state,
    loading: setLoading(state, deleteProcedureInteractionIndication, false),
    errors: setError(state, deleteProcedureInteractionIndication, action.error),
  })),
  on(recordProcedureInteractionMeasurement, (state, action) => ({
    ...state,
    loading: setLoading(state, recordProcedureInteractionMeasurement, true),
    errors: setError(state, recordProcedureInteractionMeasurement, null),
  })),
  on(recordProcedureInteractionMeasurementSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, recordProcedureInteractionMeasurement, false),
    errors: setError(state, recordProcedureInteractionMeasurement, null),
  })),
  on(recordProcedureInteractionMeasurementError, (state, action) => ({
    ...state,
    loading: setLoading(state, recordProcedureInteractionMeasurement, false),
    errors: setError(
      state,
      recordProcedureInteractionMeasurement,
      action.error,
    ),
  })),
  on(attachTimelineItemToProcedureInteraction, (state, action) => ({
    ...state,
    loading: setLoading(state, attachTimelineItemToProcedureInteraction, true),
    errors: setError(state, attachTimelineItemToProcedureInteraction, null),
  })),
  on(attachTimelineItemToProcedureInteractionSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, attachTimelineItemToProcedureInteraction, false),
    errors: setError(state, attachTimelineItemToProcedureInteraction, null),
  })),
  on(attachTimelineItemToProcedureInteractionError, (state, action) => ({
    ...state,
    loading: setLoading(state, attachTimelineItemToProcedureInteraction, false),
    errors: setError(
      state,
      attachTimelineItemToProcedureInteraction,
      action.error,
    ),
  })),
  on(addResultDocuments, state => ({
    ...state,
    loading: setLoading(state, addResultDocuments, true),
    errors: setError(state, addResultDocuments, null),
  })),
  on(addResultDocumentsSuccess, state => ({
    ...state,
    loading: setLoading(state, addResultDocuments, false),
    errors: setError(state, addResultDocuments, null),
  })),
  on(addResultDocumentsError, (state, action) => ({
    ...state,
    loading: setLoading(state, addResultDocuments, false),
    errors: setError(state, addResultDocuments, action.error),
  })),
  on(deleteResultDocument, (state, action) => ({
    ...state,
    loading: setLoading(state, deleteResultDocument, true),
    errors: setError(state, deleteResultDocument, null),
  })),
  on(deleteResultDocumentSuccess, (state, action) => ({
    ...state,
    procedureInteraction: action.procedureInteraction,
    loading: setLoading(state, deleteResultDocument, false),
    errors: setError(state, deleteResultDocument, null),
  })),
  on(deleteResultDocumentError, (state, action) => ({
    ...state,
    loading: setLoading(state, deleteResultDocument, false),
    errors: setError(state, deleteResultDocument, action.error),
  })),
);

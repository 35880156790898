import { Component, Input, OnInit } from '@angular/core';

import { getPharmacyDisplay } from '../../../../modules/shared-rx/utils/pharmacy-utils';
import { Renewal, RenewalCartState } from '../../shared/renewals.type';

@Component({
  selector: 'omg-completed-renewals-approved-and-denied',
  templateUrl: './completed-renewals-approved-and-denied.component.html',
})
export class CompletedRenewalsApprovedAndDeniedComponent {
  @Input() renewals: Renewal[] = [];

  get approvedRenewals() {
    return this.renewals.filter(
      renewal => renewal.cartState === RenewalCartState.approved,
    );
  }

  get deniedRenewals() {
    return this.renewals.filter(
      renewal => renewal.cartState === RenewalCartState.denied,
    );
  }

  get pharmacyPhrase() {
    return getPharmacyDisplay(this.renewals[0]?.pharmacy);
  }

  get countOfRenewalsPhrase() {
    return `${this.renewals.length} renewal${
      this.renewals.length > 1 ? 's' : ''
    } processed and sent to`;
  }
}

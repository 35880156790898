import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMapTo, takeUntil, tap } from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';
import { isValidPrescriber } from '@app/features/renewals/shared/renewal-utils';
import { MedicationRegimen } from '@app/modules/medications/shared';
import { PrescriberCredentialsActions } from '@app/modules/rx-cart/store/prescriber-credentials.actions';
import { PrescriberCredentialsSelectors } from '@app/modules/rx-cart/store/prescriber-credentials.selectors';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import {
  numberOfValidPrescribers,
  sortValidPrescribers,
} from '@app/modules/shared-rx/utils';

import {
  ChangeRxForm,
  customRxChangeRequestOptionId,
} from '../../shared/change-rx-form';
import {
  ChangeRx,
  ChangeRxTypes,
  RxChangeRequestOption,
} from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-medication-change',
  templateUrl: './change-rx-medication-change.component.html',
  styleUrls: ['./change-rx-medication-change.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRxMedicationChangeComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() form: ChangeRxForm;
  @Input() changeRx: ChangeRx;

  regimenEditable = true;
  changeRxTypes = ChangeRxTypes;
  rxChangeRequestOptions: RxChangeRequestOption[];

  isProvider$: Observable<boolean>;
  isCredentialsLoading$: Observable<boolean>;
  userCanPrescribe$: Observable<boolean>;

  numberOfValidPrescribers: number;
  validPrescribers: Prescriber[];

  credentialItems$: Observable<any>;

  private unsubscribe$ = new Subject();

  constructor(
    private profileSelectors: ProfileSelectors,
    private prescriberCredentialsSelectors: PrescriberCredentialsSelectors,
    private prescriberCredentialsActions: PrescriberCredentialsActions,
  ) {}

  ngOnInit(): void {
    this.setupListeners();
    this.setupSigningCredentials();
  }

  ngOnChanges(): void {
    this.setRxChangeRequestOptions();
    this.checkSelectedRxChangeRequestOptionId();
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  regimenUpdate(medicationRegimen: MedicationRegimen) {
    this.form.controls.patchValue({ medicationRegimen });
  }

  updatedSelectedRxChangeRequestOption(option: RxChangeRequestOption) {
    this.form.controls.patchValue({
      quantity: option.quantity,
      refills: option.fills,
      dispenseAsWritten: option.dispenseAsWritten,
      earliestFillDate: option.earliestFillDate,
      medicationRegimen: option.medicationRegimen,
      medicationPackageSizeId:
        option.packageOptions.defaultOption?.matchedOption?.id,
    });
  }

  private setupSigningCredentials() {
    this.numberOfValidPrescribers = numberOfValidPrescribers(
      this.changeRx.rxChangeRequest,
    );

    this.validPrescribers = sortValidPrescribers(
      this.changeRx.rxChangeRequest.validPrescribers || [],
    );

    this.userCanPrescribe$ = combineLatest([
      this.profileSelectors.hasRole('provider'),
      this.profileSelectors.profileId,
    ]).pipe(
      tap(([_, profileId]) => {
        const control = this.form.controls.get('prescriberId');
        if (!control.value && isValidPrescriber(profileId, this.validPrescribers)) {
          control.patchValue(profileId);
        }
      }),
      map(
        ([isProvider, profileId]) =>
          isProvider || isValidPrescriber(profileId, this.validPrescribers),
      ),
    );

    this.isCredentialsLoading$ = this.prescriberCredentialsSelectors.pending;
    this.credentialItems$ = this.prescriberCredentialsSelectors.credentialDropdownItems;
  }

  private setupListeners() {
    this.form.controls
      .get('prescriberId')
      .valueChanges.pipe(
        startWith(this.form.controls.get('prescriberId').value),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(prescriberId =>
        this.prescriberCredentialsActions.getPrescriberCredentials({
          cartId: this.changeRx.rxChangeRequest.rxCart.id,
          prescriberId,
        }),
      );

    this.prescriberCredentialsSelectors.pending
      .pipe(
        filter(pending => !pending),
        switchMapTo(this.prescriberCredentialsSelectors.prescriberCredentials),
        map(prescriberCredentials => prescriberCredentials.id),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(prescribingCredentialId =>
        this.form.controls.patchValue({ prescribingCredentialId }),
      );
  }

  private matchedRxChangeRequestOption(): RxChangeRequestOption {
    const {
      quantity,
      refills,
      dispenseAsWritten,
      earliestFillDate,
      medicationRegimen,
    } = this.form.controls.value;

    return this.changeRx.rxChangeRequest.rxChangeRequestOptions.find(
      option =>
        option.quantity === quantity &&
        option.fills === refills &&
        option.dispenseAsWritten === dispenseAsWritten &&
        +option.earliestFillDate === +earliestFillDate && // +'s needed for date compare
        option.medicationRegimen.id === medicationRegimen.id,
    );
  }

  private setRxChangeRequestOptions() {
    this.rxChangeRequestOptions = [].concat(
      this.changeRx.rxChangeRequest.rxChangeRequestOptions,
    );

    if (!this.matchedRxChangeRequestOption()) {
      this.rxChangeRequestOptions.push(this.buildCustomRxChangeRequestOption());
    }
  }

  private checkSelectedRxChangeRequestOptionId() {
    const matchedOptionId =
      this.matchedRxChangeRequestOption()?.id || customRxChangeRequestOptionId;
    if (matchedOptionId !== this.form.value.selectedRxChangeRequestOptionId) {
      this.form.controls.patchValue({
        selectedRxChangeRequestOptionId: matchedOptionId,
      });
    }
  }

  private buildCustomRxChangeRequestOption(): RxChangeRequestOption {
    return {
      ...this.changeRx.rxChangeRequest,
      id: customRxChangeRequestOptionId,
      custom: true,
      fills: this.changeRx.rxChangeRequest.approvedFills,
      quantity: this.changeRx.rxChangeRequest.approvedQuantity,
    };
  }
}

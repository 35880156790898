import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { take, withLatestFrom } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { TimelineActions } from '@app/features/timeline/store/timeline.actions';
import { summariesPath } from '@app/features/workspace/shared/workspace-utils';
import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';

import { MessagingService } from '../../shared/messaging.service';
import { Post } from '../../shared/messaging.type';

interface DialogData {
  post: Post;
}

@Component({
  selector: 'omg-delete-message',
  templateUrl: './delete-message.component.html',
  styleUrls: ['./delete-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteMessageComponent {
  constructor(
    public dialogRef: DialogRef<DeleteMessageComponent>,
    @Inject(OMG_DIALOG_DATA) public data: DialogData,
    private messagingService: MessagingService,
    private patientSelectors: PatientSelectors,
    private router: Router,
    private timelineActions: TimelineActions,
  ) {}

  onDeletePost() {
    this.messagingService
      .deletePost(this.data.post.id)
      .pipe(
        take(1),
        withLatestFrom(this.patientSelectors.patientId),
      )
      .subscribe(([post, patientId]) => {
        this.timelineActions.refreshTimeline();
        this.router.navigateByUrl(summariesPath(patientId, null, 'new'));
        this.dialogRef.close({ post });
      });
  }
}

import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StateSelector } from '@app/core/store/shared/state';

import {
  RxVerification,
  RxVerificationDevice,
  RxVerificationErrors,
  RxVerificationStatus,
} from '../shared/rx-verification.type';
import { RxVerificationEntityState } from './rx-verification.reducer';

export class RxVerificationSelectors extends StateSelector<RxVerification> {
  protected selectState = createFeatureSelector<RxVerificationEntityState>(
    this.stateConfig.statePath,
  );

  protected selectVerificationState = createSelector(
    this.selectState,
    state => state.verificationState,
  );

  protected selectStatus = createSelector(
    this.selectVerificationState,
    verification => verification.status,
  );

  protected selectVerificationErrors = createSelector(
    this.selectVerificationState,
    verification => verification.errors,
  );

  protected selectDevices = createSelector(
    this.selectVerificationState,
    verification => verification.devices,
  );

  protected selectToken = createSelector(
    this.selectVerificationState,
    verification => verification.token,
  );

  get verificationState(): Observable<RxVerification> {
    return this.store.pipe(select(this.selectVerificationState));
  }

  get errors(): Observable<RxVerificationErrors> {
    return this.store.pipe(select(this.selectVerificationErrors));
  }

  get status(): Observable<RxVerificationStatus> {
    return this.store.pipe(select(this.selectStatus));
  }

  get devices(): Observable<RxVerificationDevice[]> {
    return this.store.pipe(select(this.selectDevices));
  }

  get token(): Observable<string> {
    return this.store.pipe(select(this.selectToken));
  }
}

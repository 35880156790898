import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { InternalUser } from './internal-user.type';

interface InternalUsersVariables {
  ids: string[];
}

export interface InternalUsersResponseData {
  internalUsers: {
    nodes: InternalUser[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class InternalUserGraphQLService extends Query<
  InternalUsersResponseData,
  InternalUsersVariables
> {
  document = gql`
    query InternalUsers($ids: [ID!]) {
      internalUsers(ids: $ids) {
        nodes {
          id
          firstName
          lastName
          suffix
          initials
          profileImageUrl
          preferredRole {
            id
            name
          }
          nonPreferredRoles {
            id
          }
          workSchedule {
            id
            returningOn
            mondaysOff
            tuesdaysOff
            wednesdaysOff
            thursdaysOff
            fridaysOff
            saturdaysOff
            sundaysOff
          }
          defaultOffice {
            id
            name
          }
          serviceArea {
            id
            name
          }
        }
      }
    }
  `;
}

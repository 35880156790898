import { PrescriberCredentials } from '@app/modules/shared-rx/prescriber-credential.type';

import {
  PrescriberCredentialsAction,
  PrescriberCredentialsActionTypes,
} from './prescriber-credentials.actions';

export interface PrescriberCredentialsState {
  entity: PrescriberCredentials;
  pending: boolean;
  error: any;
}

export const initialState: PrescriberCredentialsState = {
  entity: null,
  error: null,
  pending: false,
};

export function reducer(
  state = initialState,
  action: PrescriberCredentialsAction,
): PrescriberCredentialsState {
  switch (action.type) {
    case PrescriberCredentialsActionTypes.GetPrescriberCredentials: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case PrescriberCredentialsActionTypes.GetPrescriberCrentialsSuccess: {
      return {
        ...state,
        pending: false,
        error: null,
        entity: action.payload,
      };
    }

    case PrescriberCredentialsActionTypes.GetPrescriberCredentialsError: {
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Re-export functions from lodash/fp
 * - https://github.com/lodash/lodash/wiki/FP-Guide
 */

export {
  castArray,
  cloneDeep,
  compact,
  curry,
  defaultTo,
  escapeRegExp,
  difference,
  flow,
  filter,
  first,
  flatMap,
  flatten,
  flattenDeep,
  flow as pipe,
  get,
  getOr,
  head,
  identity,
  invoke,
  isArray,
  isDate,
  isEqual,
  isEmpty,
  isError,
  isNumber,
  isObject,
  isPlainObject,
  isRegExp,
  isString,
  join,
  last,
  lowerCase,
  map,
  mapValues,
  merge,
  omit,
  orderBy,
  pickBy,
  range,
  reduce,
  remove,
  set,
  sortBy,
  startCase,
  without,
  noop,
  unionWith,
  upperFirst,
  keyBy,
  random,
} from 'lodash/fp';

// uncapped versions
export { pad } from 'lodash';

import { PatientAddress } from '@app/core';
import { PatientResponseAddress } from '@app/core/patient/shared/patient-response.type';
import { ProfileOfficeResponse } from '@app/core/profile/shared/profile-response.type';

export const fullAddressDisplay = (
  address: PatientResponseAddress | ProfileOfficeResponse | PatientAddress,
  separator = ',',
): string => {
  if (!address) {
    return '';
  }

  let display =
    (<PatientResponseAddress | PatientAddress>address).street ||
    (<ProfileOfficeResponse>address).address1;
  const zip =
    (<PatientResponseAddress>address).zip_code ||
    (<PatientAddress>address).zipCode ||
    (<ProfileOfficeResponse>address).zip;
  const state =
    address.state &&
    ((<PatientResponseAddress | ProfileOfficeResponse>address).state
      .short_name ||
      (<PatientAddress>address).state.shortName);
  if (address.address2) {
    display += `${separator} ${address.address2}`;
  }
  return `${display}${separator} ${address.city}, ${state} ${zip}`;
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'omgTruncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(text: string, limit = null, trail = '...'): string {
    const trimmedText = text.trim();
    return limit && trimmedText.length > limit
      ? `${trimmedText.slice(null, limit)}${trail}`
      : trimmedText;
  }
}

import * as Rollbar from 'rollbar';

import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  Optional,
} from '@angular/core';
import { environment } from '@environments/environment';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(environment.rollbar);
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Optional() @Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any) {
    if (this.rollbar) {
      this.rollbar.error(err.originalError || err);
    }
    return err;
  }
}

<div class="om-item">
  <div class="banner -success"
       om-layout-gutter
       om-layout="vertical"
       data-cy="change-rx-toast-msg">
    <div>1 <strong>{{ changeRxTypes[changeRxSubmitAction.changeRx.changeRequestType] }}</strong> response processed and
      sent to</div>
    <strong>
      {{ pharmacyPhrase }}
    </strong>
    <div>
      <strong>{{changeRxSubmitAction.submitAction}}</strong>
      <ul>
        <li *ngIf="changeRxSubmitAction.changeRx.originalMedicationPrescription.medForDisplay as medForDisplay">
          {{ medForDisplay.name }} {{ medForDisplay.dispensableTextDesc }}
        </li>
      </ul>
    </div>
  </div>
</div>

import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ResizeObserverEntry } from './detect-resize.type';

declare const ResizeObserver;

@Injectable()
export class DetectResizeService {
  getResizeEvents$(el: ElementRef | HTMLElement, delay = 100) {
    return this.createResizeObserver$(el).pipe(
      debounceTime(delay),
      distinctUntilChanged(),
      map(entries => (entries && entries[0] ? entries[0].contentRect : null)),
    );
  }

  private createResizeObserver$(
    el: ElementRef | HTMLElement,
  ): Observable<ResizeObserverEntry[]> {
    return Observable.create(obs => {
      const callback = (entries: ResizeObserverEntry[]) => obs.next(entries);
      const resizeObserver = new ResizeObserver(callback);
      resizeObserver.observe(
        el && (<ElementRef>el).nativeElement
          ? (<ElementRef>el).nativeElement
          : el,
      );
      return () => resizeObserver.disconnect();
    });
  }
}

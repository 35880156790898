import { ErrorResult } from '../../errors';
import { FeatureFlag } from '../shared/feature-flag.type';
import * as featureFlagActions from './feature-flag.actions';

export const featureFlagStatePath = 'featureFlags';

export interface FeatureFlagState {
  entity: FeatureFlag[];
  error: ErrorResult[];
  loading: boolean;
}

export const featureFlagInitialState: FeatureFlagState = {
  entity: null,
  error: [],
  loading: false,
};

export function featureFlagReducer(
  state = featureFlagInitialState,
  action: featureFlagActions.FeatureFlagAction,
): FeatureFlagState {
  switch (action.type) {
    case featureFlagActions.GET_FEATURE_FLAGS: {
      return {
        ...state,
        loading: true,
      };
    }

    case featureFlagActions.GET_FEATURE_FLAGS_SUCCESS: {
      return {
        ...state,
        error: [],
        loading: false,
        entity: action.payload,
      };
    }

    case featureFlagActions.GET_FEATURE_FLAGS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        entity: null,
      };
    }

    default: {
      return { ...state };
    }
  }
}
